import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

function RowComponent(data) {
  const material = useSelector((e) => e.material);
  const [dataMaterial, setDataMaterial] = useState([]);
  const requestorder = useSelector((state) => state.requestOrder);
  const [input, setInput] = useState(requestorder);
  // const [value, setValue] = useState('');
  // const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const find = requestorder.find((e) => e.id === data.id);
    setInput(find);
    // setValue(find.label);
    // setInputValue(find.label);
  }, [data, requestorder]);

  const handleChange = (name, e) => {
    const newInput = { ...input, [name]: e.target.value };
    setInput(newInput);
    dispatch({ type: 'UPDATE_REQUEST_ORDER', data: newInput });
  };

  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch({ type: 'REMOVE_REQUEST_ORDER_BY_ID', id: data.id });
  };

  useEffect(() => {
    const updatedDataMaterial = material.map((item) => ({
      ...item,
      label: `${item.partCode} ${item.deskripsi} ${item.merk}`,
    }));
    setDataMaterial(updatedDataMaterial);
  }, []);

  const handleClickMaterial = (str) => {
    let newInput = input;
    if (str) {
      const s = dataMaterial.find((e) => e.label.toLowerCase().match(str.toLowerCase()));
      newInput = {
        ...input,
        partCode: s.partCode,
        deskripsi: s.deskripsi,
        spesifikasi: s.spesifikasi,
        satuan: s.sat,
        label: str,
      };
      setInput(newInput);
    } else {
      newInput = {
        ...input,
        partCode: '',
        deskripsi: '',
        spesifikasi: '',
        satuan: '',
        label: str,
      };
      setInput(newInput);
    }
    dispatch({ type: 'UPDATE_REQUEST_ORDER', data: newInput });
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Kode Material"
          variant="outlined"
          size="small"
          variant="standard"
          type="number"
          disabled
          value={input.partCode || ''}
          fullWidth
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dataMaterial}
          // sx={{ width: 300 }}
          size="small"
          value={input.label}
          onChange={(event, newValue) => {
            // handleClickMaterial(newValue);
            console.log(newValue);
          }}
          inputValue={input.label}
          onInputChange={(event, newInputValue) => {
            // setInputValue(newInputValue);
            // console.log(newInputValue);
            handleClickMaterial(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label="Material" variant="standard" />}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <FormControl fullWidth sx={{ paddingTop: 0 }} size="small" variant="standard">
          <InputLabel id="demo-simple-select-label">Spesifikasi</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input.spesifikasi || ''}
            label="Spesifikasi"
            onChange={(e) => handleChange('spesifikasi', e)}
          >
            <MenuItem value="Asset">Asset</MenuItem>
            <MenuItem value="Inventory">Inventory</MenuItem>
            {/* <MenuItem value="ATK">ATK</MenuItem>
            <MenuItem value="Bulding">Bulding</MenuItem>
            <MenuItem value="Buldings">Buldings</MenuItem>
            <MenuItem value="Hospitality">Hospitality</MenuItem>
            <MenuItem value="Meals">Meals</MenuItem>
            <MenuItem value="Union">Union</MenuItem>
            <MenuItem value="Electric">Electric</MenuItem>
            <MenuItem value="Asset">Asset</MenuItem> */}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Jumlah"
          variant="outlined"
          size="small"
          type="number"
          variant="standard"
          value={input.qty || ''}
          onChange={(e) => handleChange('qty', e)}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <FormControl fullWidth sx={{ paddingTop: 0 }} size="small" variant="standard">
          <InputLabel id="demo-simple-select-label">Satuan</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input.satuan || ''}
            label="Satuan"
            onChange={(e) => handleChange('satuan', e)}
          >
            <MenuItem value="Pcs">PCS</MenuItem>
            <MenuItem value="Pack">PACK</MenuItem>
            <MenuItem value="Lbr">LEBAR</MenuItem>
            <MenuItem value="Kotak">KOTAK</MenuItem>
            <MenuItem value="Buku">BUKU</MenuItem>
            <MenuItem value="rim">RIM</MenuItem>
            <MenuItem value="Pail">PAIL</MenuItem>
            <MenuItem value="Set">SET</MenuItem>
            <MenuItem value="Btl">BOTOL</MenuItem>
            <MenuItem value="Bks">BKS</MenuItem>
            <MenuItem value="Mtr">MTR</MenuItem>
            <MenuItem value="Roll">ROLL</MenuItem>
            <MenuItem value="Box">BOX</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Keterangan"
          variant="outlined"
          size="small"
          variant="standard"
          value={input.note || ''}
          onChange={(e) => handleChange('note', e)}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default RowComponent;
