import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// component
import Iconify from '../../../components/iconify';

export default function TitleBar() {
  return (
    <Grid>
      <Typography variant="h4" color="primary">
        Mandiri Coal
      </Typography>
    </Grid>
  );
}
