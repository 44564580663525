import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ListToolbar, ListHead } from '../../sections/@dashboard/listHead';

import Form from './components/Form';
import AppModal from '../../sections/@dashboard/app/AppModal';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'partCode', label: 'Part Kode', alignRight: false },
  { id: 'deskripsi', label: 'Material', alignRight: false },
  { id: 'minimumStock', label: 'Minimum Stock', alignRight: false },
  { id: 'spesifikasi', label: 'Spesifikasi', alignRight: false },
  { id: 'sat', label: 'Satuan', alignRight: false },
  { id: '', label: 'Aksi', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (item) => item.deskripsi.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Material() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('material');

  const [filterMaterial, setFilterMaterial] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();

  const MATERIALLIST = useSelector((state) => state.material);

  const [rowClick, setRowClick] = useState([]);

  const handleTambah = () => {
    return <Form button="Tambah" onButtonClick={() => getData()} />;
  };

  const handleEdit = () => {
    return <Form button="Edit" data={rowClick} onButtonClick={() => getData()} />;
  };

  const handleHapus = () => {
    return <Form button="Hapus" data={rowClick} onButtonClick={() => getData()} />;
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = MATERIALLIST.map((n) => n.codePart);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByMaterial = (event) => {
    setPage(0);
    setFilterMaterial(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - MATERIALLIST.length) : 0;

  const filtered = applySortFilter(MATERIALLIST, getComparator(order, orderBy), filterMaterial);

  const isNotFound = !filtered.length && !!filterMaterial;

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API}/material`)
      .then((result) => {
        const ress = result.data.data;
        dispatch({ type: 'NEW_MATERIAL', data: ress });
        // console.log(ress);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Container maxWidth="100%">
        <Helmet>
          <title> Barang </title>
        </Helmet>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Barang
          </Typography>
          <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label={'"month" and "year"'} views={['month', 'year']} size="small" />
              </DemoContainer>
            </LocalizationProvider>
            <Button
              variant="contained"
              color="success"
              startIcon={<Iconify icon="eva:plus-fill" />}
              size="large"
              sx={{ marginTop: 1, marginLeft: 2 }}
            >
              Export
            </Button> */}
            <AppModal
              title="Tambah Data"
              form={(handleClose) => <Form button="Tambah" onButtonClick={() => getData()} handleClose={handleClose} />}
              button="Tambah"
            />
          </Grid>
        </Stack>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterMaterial={filterMaterial}
            onFilterMaterial={handleFilterByMaterial}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={MATERIALLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { partCode, deskripsi, spesifikasi, sat, minimumStock } = row;
                    const selectedInventory = selected.indexOf(partCode) !== -1;

                    return (
                      <TableRow hover key={partCode} tabIndex={-1} role="checkbox" selected={selectedInventory}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedInventory} onChange={(event) => handleClick(event, partCode)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {partCode}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {deskripsi}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{minimumStock}</TableCell>

                        <TableCell align="left">
                          <Label color={(spesifikasi === 'Asset' && 'error') || 'success'}>
                            {sentenceCase(spesifikasi)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{sat}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e);
                              setRowClick(row);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ada hasil untuk &nbsp;
                            <strong>&quot;{filterMaterial}&quot;</strong>.
                            <br /> Silahkan coba lagi dengan menggunakan kata yang berbeda.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={MATERIALLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          {/* <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}

          <AppModal
            title="Edit Data"
            form={(handleClose) => (
              <Form button="Edit" data={rowClick} onButtonClick={() => getData()} handleClose={handleClose} />
            )}
            button="Edit"
          />
          <AppModal
            title="Hapus Data"
            form={(handleClose) => (
              <Form button="Hapus" data={rowClick} onButtonClick={() => getData()} handleClose={handleClose} />
            )}
            button="Hapus"
          />
          {/* <AppBasicModal title="Hapus" form={handleDelete} button="Hapus" description="Hapus Material 'Tisu Basah'" /> */}
        </Popover>
      </Container>
    </>
  );
}
