import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Stack,
  Typography,
  Badge,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { createBarangMasuk, getBarangMasuk, getRequestOrderProses } from '../../../utils/api';
import Iconify from '../../../components/iconify/Iconify';
import FormRequestOrderList from './FormRequestOrderList';
import AppModal from '../../../sections/@dashboard/app/AppModal';

function FormRequestOrder() {
  const [drow, setDrow] = useState([]);

  const handleTambahRequestOrderList = (row) => {
    return <FormRequestOrderList button="Tambah" data={row} />;
  };

  const getData = () => {
    getRequestOrderProses()
      .then((result) => {
        const s = result.data.data;
        setDrow(s);
        console.log(s);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
        <Table stickyHeader sx={{ width: 1200, marginBottom: 2 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="20%">No RO</TableCell>
              <TableCell width="20%">No PR</TableCell>
              <TableCell width="20%">No PO</TableCell>
              <TableCell width="10%" align="left">
                Urgency
              </TableCell>
              <TableCell width="10%" align="left">
                Tanggal
              </TableCell>
              <TableCell width="10%" align="left">
                Status
              </TableCell>
              <TableCell width="10%" align="right">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drow.map((row) => {
              const { noRo, noPr, noPo, tanggal, urgency, status } = row;

              return (
                <TableRow hover key={noRo} tabIndex={-1}>
                  <TableCell component="th" scope="row" padding="none">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="subtitle2" noWrap>
                        {noRo}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell align="left">
                    <Typography variant="subtitle2" noWrap>
                      {noPr || '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle2" noWrap>
                      {noPo || '-'}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">{moment(tanggal).tz('Asia/Jakarta').format('YYYY-MM-DD')}</TableCell>
                  <TableCell align="left">{urgency || '-'}</TableCell>
                  <TableCell align="left">
                    <Badge badgeContent="Proses" color="primary" />
                  </TableCell>

                  <TableCell align="right">
                    <AppModal
                      title="Custom"
                      form={() => handleTambahRequestOrderList(row)}
                      button="IconButton"
                      iconButton={<ArrowForwardIosIcon />}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default FormRequestOrder;
