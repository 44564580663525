import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, MenuItem, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { sentenceCase } from 'change-case';

import Iconify from '../../../components/iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

export default function AppModal({ title, form, button, description = '', iconButton = null }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      {button === 'Tambah' && (
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          size="large"
          sx={{ marginTop: 1, marginLeft: 5 }}
          onClick={handleOpen}
        >
          {title}
        </Button>
      )}
      {button === 'Edit' && (
        <MenuItem onClick={handleOpen}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {title}
        </MenuItem>
      )}
      {button === 'Hapus' && (
        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpen}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {title}
        </MenuItem>
      )}
      {button === 'IconButton' && (
        <IconButton size="large" color="primary" onClick={handleOpen}>
          {iconButton}
        </IconButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack alignItems="center" justifyContent="space-between" direction="row" sx={{ paddingBottom: 2 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {sentenceCase(title)}
            </Typography>
            <IconButton variant="contained" onClick={handleClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Stack>
          {description && (
            <Typography id="modal-modal-description" sx={{ mb: 2, textAlign: 'center' }}>
              {description}
            </Typography>
          )}
          {form(handleClose)}
        </Box>
      </Modal>
    </div>
  );
}
