import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Stack,
  Button,
  Typography,
  TableCell,
} from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import Iconify from '../../../components/iconify';

function capitalizeFirstWord(text) {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  return '';
}

function Form({ button, data = [], onButtonClick, handleClose }) {
  const [input, setInput] = useState(data);
  const [danger, setDanger] = useState('');
  const dispatch = useDispatch();

  const handleChange = (e, name) => {
    setInput({ ...input, [name]: e.target.value });
  };

  const refreshTable = () => {
    onButtonClick();
  };

  const handleDanger = (message) => {
    setDanger(message);
    setTimeout(() => {
      setDanger('');
    }, 3000);
  };

  const handleSubmit = () => {
    if (button === 'Tambah') {
      axios
        .post(`${process.env.REACT_APP_API}/material`, input)
        .then((result) => {
          console.log(result.data);
          // const ress = result.data.data;
          dispatch({ type: 'ADD_MATERIAL', data: input });
          // refreshTable();
          handleClose();
        })
        .catch((err) => {
          // console.log(err.response.status);
          handleDanger('Kesalahan server atau data sudah ada');
        });
    } else if (button === 'Edit') {
      axios
        .patch(`${process.env.REACT_APP_API}/material/${input.partCode}`, input)
        .then((result) => {
          console.log(result.data);
          // const ress = result.data.data;
          dispatch({ type: 'UPDATE_MATERIAL', data: input });
          // refreshTable();
          handleClose();
        })
        .catch((err) => {
          // console.log(err.response.status);
          handleDanger('Kesalahan server atau data sudah ada');
        });
    } else if (button === 'Hapus') {
      axios
        .delete(`${process.env.REACT_APP_API}/material/${input.partCode}`)
        .then((result) => {
          console.log(result.data);
          // const ress = result.data.data;
          dispatch({ type: 'DELETE_MATERIAL', data: input });
          // refreshTable();
          handleClose();
        })
        .catch((err) => {
          // console.log(err.response.status);
          handleDanger('Kesalahan server atau data sudah ada');
        });
    }
  };

  return (
    <Stack sx={{ width: 600 }}>
      {button === 'Hapus' && (
        <Typography id="modal-modal-description" sx={{ mb: 2, textAlign: 'center' }}>
          Anda yakin ingin menghapus data tersebut
        </Typography>
      )}
      {button !== 'Hapus' && (
        <>
          {button === 'Edit' && (
            <TextField
              sx={{ marginBottom: 2 }}
              id="outlined-basic"
              label="Part Code"
              variant="outlined"
              value={input.partCode || ''}
              disabled
            />
          )}
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="demo-simple-select-label">Spesifikasi</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipe Material"
              value={input.spesifikasi || ''}
              onChange={(e) => handleChange(e, 'spesifikasi')}
            >
              <MenuItem value="Inventory">Inventory</MenuItem>
              <MenuItem value="Asset">Asset</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ marginBottom: 2 }}
            id="outlined-basic"
            label="Nama Material"
            variant="outlined"
            value={input.deskripsi || ''}
            onChange={(e) => handleChange(e, 'deskripsi')}
          />
          <TextField
            sx={{ marginBottom: 2 }}
            id="filled-number"
            label="Merk"
            variant="outlined"
            value={input.merk || ''}
            onChange={(e) => handleChange(e, 'merk')}
          />
          <TextField
            sx={{ marginBottom: 2 }}
            id="filled-number"
            label="Type"
            variant="outlined"
            value={input.type || ''}
            onChange={(e) => handleChange(e, 'type')}
          />
          <FormControl sx={{ marginBottom: 2 }}>
            <InputLabel id="demo-simple-select-label">Satuan</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={capitalizeFirstWord(input.sat) || ''}
              label="Satuan"
              onChange={(e) => handleChange(e, 'sat')}
            >
              <MenuItem value="Pcs">PCS</MenuItem>
              <MenuItem value="Pack">PACK</MenuItem>
              <MenuItem value="Lbr">LEBAR</MenuItem>
              <MenuItem value="Kotak">KOTAK</MenuItem>
              <MenuItem value="Buku">BUKU</MenuItem>
              <MenuItem value="rim">RIM</MenuItem>
              <MenuItem value="Pail">PAIL</MenuItem>
              <MenuItem value="Set">SET</MenuItem>
              <MenuItem value="Btl">BOTOL</MenuItem>
              <MenuItem value="Bks">BKS</MenuItem>
              <MenuItem value="Mtr">MTR</MenuItem>
              <MenuItem value="Roll">ROLL</MenuItem>
              <MenuItem value="Box">BOX</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ marginBottom: 2 }}
            id="outlined-basic"
            label="Minimum Stock"
            type="number"
            variant="outlined"
            value={input.minimumStock || ''}
            onChange={(e) => handleChange(e, 'minimumStock')}
          />
        </>
      )}
      <Stack direction="row" justifyContent="center" alignItems="center">
        {button === 'Tambah' && (
          <Button variant="contained" startIcon={<Iconify icon="eva:save-fill" />} size="large" onClick={handleSubmit}>
            Simpan
          </Button>
        )}
        {button === 'Edit' && (
          <Button
            variant="contained"
            color="success"
            startIcon={<Iconify icon="eva:edit-2-fill" />}
            onClick={handleSubmit}
            size="large"
          >
            Simpan Perubahan
          </Button>
        )}
        {button === 'Hapus' && (
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:edit-2-fill" />}
            onClick={handleSubmit}
            size="large"
          >
            Ya Hapus
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export default Form;
