import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  TableHead,
  TableFooter,
  FormControl,
  Select,
  InputLabel,
  TextField,
  Autocomplete,
  Modal,
  Badge,
} from '@mui/material';
import axios from 'axios';

// icon
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ListToolbar, ListHead } from '../../sections/@dashboard/listHead';
import { AppBasicModal } from '../../sections/@dashboard/app';
// mock
// import REQUESTORDERLIST from '../../_mock/requestorder';

import RowComponent from '../../components/RowComponent';

import FormRequest from './components/FormRequest';
import FormTerima from './components/FormTerima';
import AppModal from '../../sections/@dashboard/app/AppModal';
import { getRequestOrderList } from '../../utils/api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'noRO', label: 'No RO', alignRight: false },
  { id: 'noPR', label: 'No PR', alignRight: false },
  { id: 'noPO', label: 'No PO', alignRight: false },
  { id: 'urgency', label: 'Urgency', alignRight: false },
  { id: 'tanggal', label: 'Tanggal', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', label: 'Aksi', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.material.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// const form = [
//   {
//     id: 'materialId',
//     label: 'Barang/Material',
//     type: 'select',
//     item: [
//       { id: '1', label: 'Kursi' },
//       { id: '2', label: 'Meja' },
//       { id: '3', label: 'Pensil' },
//     ],
//     value: '',
//   },
//   {
//     id: 'warehouseId',
//     label: 'Gudang/Warehouse',
//     type: 'select',
//     item: [
//       { id: 'KM6', label: 'KM 6' },
//       { id: 'KM68', label: 'KM 68' },
//     ],
//     value: '',
//   },
//   {
//     id: 'stockMasuk',
//     label: 'Stock Masuk',
//     type: 'number',
//     item: '',
//     value: '',
//   },
//   {
//     id: 'stockKeluar',
//     label: 'Stock Keluar',
//     type: 'number',
//     item: '',
//     value: '',
//   },
//   {
//     id: 'stockAwal',
//     label: 'Stock Awal',
//     type: 'number',
//     item: '',
//     value: '',
//   },
//   {
//     id: 'stockAkhir',
//     label: 'Stock Akhir',
//     type: 'number',
//     item: '',
//     value: '',
//   },
//   {
//     id: 'periode',
//     label: 'Periode',
//     type: 'monthyear',
//     item: '',
//     value: '',
//   },
//   {
//     id: 'tipe',
//     label: 'Tipe',
//     type: 'select',
//     item: [
//       { id: 'Asset', label: 'Asset' },
//       { id: 'Inventory', label: 'Inventory' },
//     ],
//     value: '',
//   },
// ];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 720,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function RequestOrder() {
  const LIST = useSelector((state) => state.requestOrderList);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('material');
  const [filterMaterial, setFilterMaterial] = useState('');

  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [age, setAge] = useState('');
  const handleChange = (e) => {
    setAge(e.target.value);
  };

  const [rowClick, setRowClick] = useState([]);

  const getData = () => {
    getRequestOrderList()
      .then((result) => {
        const s = result.data.data;
        console.log(s);
        dispatch({ type: 'NEW_REQUEST_ORDER_LIST', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleTambah = () => {
    return <FormRequest button="Tambah" />;
  };

  const handleEdit = () => {
    return <FormTerima button="Edit" data={rowClick} />;
  };

  const handleHapus = () => {
    return <FormRequest button="Hapus" data={rowClick} />;
  };

  const [openModal, setOpenModal] = useState(false);
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = LIST.map((n) => n.noRo);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByMaterial = (event) => {
    setPage(0);
    setFilterMaterial(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LIST.length) : 0;

  const filteredInventory = applySortFilter(LIST, getComparator(order, orderBy), filterMaterial);

  const isNotFound = !filteredInventory.length && !!filterMaterial;

  return (
    <>
      <Container maxWidth="100%">
        <Helmet>
          <title> Request Order </title>
        </Helmet>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Request Order
          </Typography>
          <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label={'"month" and "year"'} views={['month', 'year']} size="small" />
              </DemoContainer>
            </LocalizationProvider>
            <Button
              variant="contained"
              color="success"
              startIcon={<Iconify icon="eva:plus-fill" />}
              size="large"
              sx={{ marginTop: 1, marginLeft: 2 }}
            >
              Export
            </Button> */}
            {/* <AppBasicModal title="Tambah Data" form={form} button="Tambah" /> */}
            <AppModal
              title="Tambah Data"
              form={(handleClose) => <FormRequest button="Tambah" closeModal={handleClose} />}
              button="Tambah"
            />
          </Grid>
        </Stack>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterMaterial={filterMaterial}
            onFilterMaterial={handleFilterByMaterial}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={LIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredInventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { noRo, noPr, noPo, tanggal, urgency } = row;
                    const selectedInventory = selected.indexOf(noRo) !== -1;

                    return (
                      <TableRow hover key={noRo} tabIndex={-1} role="checkbox" selected={selectedInventory}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedInventory} onChange={(event) => handleClick(event, noRo)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {noRo}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {noPr || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {noPo || '-'}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{urgency || '-'}</TableCell>
                        <TableCell align="left">{moment(tanggal).tz('Asia/Jakarta').format('YYYY-MM-DD')}</TableCell>
                        <TableCell align="left">
                          <Badge badgeContent="Proses" color="primary" />
                        </TableCell>

                        {/* <TableCell align="left">
                          <Label color={(tipe === 'Asset' && 'error') || 'success'}>{sentenceCase(tipe)}</Label>
                        </TableCell> */}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ada hasil untuk &nbsp;
                            <strong>&quot;{filterMaterial}&quot;</strong>.
                            <br /> Silahkan coba lagi dengan menggunakan kata yang berbeda.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={LIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <AppModal
            title="Edit"
            form={(handleClose) => <FormTerima button="Edit" data={rowClick} closeModal={handleClose} />}
            button="Edit"
            description=""
          />
          {/* <AppBasicModal title="Hapus" form={[]} button="Hapus" description="Hapus Inventory dengan kode JGG767" /> */}
        </Popover>
      </Container>

      <Modal
        open={openModal}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid sx={{ marginBottom: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton onClick={handleModal}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
];
