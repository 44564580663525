import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  TableHead,
  Box,
  Modal,
} from '@mui/material';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

// icon
import ClearIcon from '@mui/icons-material/Clear';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import SOComponent from '../../components/SOComponent';
// sections
import { ListToolbar, ListHead } from '../../sections/@dashboard/listHead';
import { AppWidgetSummaryLeftIcon, AppBasicModal } from '../../sections/@dashboard/app';
// mock
import LIST from '../../_mock/stockkeluar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'kodeMaterial', label: 'Kode Material', alignRight: false },
  { id: 'namaMaterial', label: 'Nama Material', alignRight: false },
  { id: 'keterangan', label: 'Keterangan', alignRight: false },
  { id: 'namaWarehouse', label: 'Warehouse', alignRight: false },
  { id: 'merek', label: 'Merek', alignRight: false },
  { id: 'type', label: 'Tipe', alignRight: false },
  { id: 'quantity', label: 'Qty', alignRight: false },
  { id: 'satuan', label: 'Satuan', alignRight: false },
  { id: 'request', label: 'Request', alignRight: false },
  { id: 'departement', label: 'Departement', alignRight: false },
  { id: 'tanggal', label: 'Tanggal', alignRight: false },
  // { id: '', label: 'Aksi' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.namaWarehouse.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const form = [
  {
    id: 'materialId',
    label: 'Barang/Material',
    type: 'select',
    item: [
      { id: '1', label: 'Kursi' },
      { id: '2', label: 'Meja' },
      { id: '3', label: 'Pensil' },
    ],
    value: '',
  },
  {
    id: 'warehouseId',
    label: 'Warehouse/Gudang',
    type: 'select',
    item: [
      { id: 'KM6', label: 'KM 6' },
      { id: 'KM68', label: 'KM 68' },
    ],
    value: '',
  },
  {
    id: 'quantity',
    label: 'Qty',
    type: 'number',
    item: '',
    value: '',
  },
  {
    id: 'namaPenerima',
    label: 'Nama Penerima',
    type: 'text',
    item: '',
    value: '',
  },
  {
    id: 'tanggal',
    label: 'Tanggal',
    type: 'date',
    item: '',
    value: '',
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 720,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function StockKeluar() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('namaMaterial');

  const [filterWarehouse, setFilterWarehouse] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = LIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByMaterial = (event) => {
    setPage(0);
    setFilterWarehouse(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LIST.length) : 0;

  const filtered = applySortFilter(LIST, getComparator(order, orderBy), filterWarehouse);

  const isNotFound = !filtered.length && !!filterWarehouse;

  const drow = useSelector((state) => state.stockKeluar);
  const handleAddRow = () => {
    const noID = moment().valueOf();
    dispatch({ type: 'NEW_STOCK_KELUAR', data: { id: noID } });
  };

  return (
    <>
      <Container maxWidth="100%">
        <Helmet>
          <title> Stock Keluar </title>
        </Helmet>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Stock Keluar
          </Typography>
          <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label={'"month" and "year"'} views={['month', 'year']} size="small" />
              </DemoContainer>
            </LocalizationProvider>
            <Button
              variant="contained"
              color="success"
              startIcon={<Iconify icon="eva:plus-fill" />}
              size="large"
              sx={{ marginTop: 1, marginLeft: 2 }}
            >
              Export
            </Button>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              size="large"
              sx={{ marginTop: 1, marginLeft: 5 }}
              onClick={handleModal}
            >
              Tambah
            </Button>
          </Grid>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterWarehouse={filterWarehouse}
            onfilterWarehouse={handleFilterByMaterial}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={LIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      kodeMaterial,
                      namaMaterial,
                      keterangan,
                      warehouse,
                      merek,
                      type,
                      quantity,
                      satuan,
                      request,
                      departement,
                      tanggal,
                    } = row;
                    const selectedInventory = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedInventory}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedInventory} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {kodeMaterial}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{namaMaterial}</TableCell>
                        <TableCell align="left">{keterangan}</TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {warehouse}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{merek}</TableCell>
                        <TableCell align="left">{type}</TableCell>
                        <TableCell align="left">{quantity}</TableCell>
                        <TableCell align="left">{satuan}</TableCell>
                        <TableCell align="left">{request}</TableCell>
                        <TableCell align="left">{departement}</TableCell>
                        <TableCell align="left">{tanggal}</TableCell>

                        {/* <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ada hasil untuk &nbsp;
                            <strong>&quot;{filterWarehouse}&quot;</strong>.
                            <br /> Silahkan coba lagi dengan menggunakan kata yang berbeda.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={LIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          {/* <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
          <AppBasicModal title="Edit" form={form} button="Edit" description="" />
          <AppBasicModal title="Hapus" form={[]} button="Hapus" description="Hapus Inventory dengan kode JGG767" />
        </Popover>
      </Container>
      <Modal
        open={openModal}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid sx={{ marginBottom: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Tambah Request Order
            </Typography>
            <IconButton onClick={handleModal}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
            <Table stickyHeader sx={{ width: 1200 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="30%">Nama Material</TableCell>
                  <TableCell width="10%" align="left">
                    Keterangan
                  </TableCell>
                  <TableCell width="10%" align="left">
                    Warehouse
                  </TableCell>
                  <TableCell width="5%" align="left">
                    Merek
                  </TableCell>
                  <TableCell width="10%" align="left">
                    Tipe
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Qty
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Satuan
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Request
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Departement
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Tanggal
                  </TableCell>
                  <TableCell width="5%" align="right">
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {drow.map((item, index) => {
                  return <SOComponent {...item} key={index} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper
            sx={{
              textAlign: 'center',
              marginTop: 1,
            }}
          >
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAddRow}>
              Tambah
            </Button>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}
