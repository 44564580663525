import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const fanincial = [...Array(3)].map((_, index) => ({
  id: faker.datatype.uuid(),
  namaMaterial: sample(['Meja', 'Kursi', 'Spidol']),
  fianncialMasuk: sample([100000, 150000]),
  financialKeluar: sample([50000, 25000]),
  financialTotalAkhir: sample([55000, 700000]),
  periode: sample(['10-2023', '10-2023']),
  tanggal: sample(['10-10-2023', '09-10-2023']),
}));

export default fanincial;
