import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';

function BarangKeluarInventoryRowComponent(data) {
  const material = useSelector((e) => e.barangKeluarMaterial);
  const [dataMaterial, setDataMaterial] = useState([]);
  const barangKeluar = useSelector((state) => state.barangKeluarList);
  const [input, setInput] = useState(barangKeluar);

  useEffect(() => {
    const find = barangKeluar.find((e) => e.id === data.id);
    setInput(find);
  }, [data, barangKeluar]);

  const handleChange = (name, e) => {
    const newInput = { ...input, [name]: e.target.value };
    setInput(newInput);
    dispatch({ type: 'UPDATE_BARANG_KELUAR_LIST', data: newInput });
  };

  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch({ type: 'REMOVE_BARANG_KELUAR_LIST_BY_ID', id: data.id });
  };

  useEffect(() => {
    const updatedDataMaterial = material.map((item) => ({
      ...item,
      label: `${item.partCode} ${item.deskripsi} ${item.merk || ''}`,
    }));
    setDataMaterial(updatedDataMaterial);
  }, []);

  const handleClickMaterial = (str) => {
    let newInput = input;
    if (str) {
      const s = dataMaterial.find((e) => e.label.toLowerCase().match(str.toLowerCase()));
      newInput = {
        ...input,
        partCode: s.partCode,
        deskripsi: s.deskripsi,
        stockAkhir: s.stockAkhir,
        noGr: s.noGr,
        spesifikasi: s.spesifikasi,
        satuan: s.sat,
        label: str,
      };
      setInput(newInput);
    } else {
      newInput = {
        ...input,
        partCode: '',
        deskripsi: '',
        stockAkhir: '',
        noGr: '',
        spesifikasi: '',
        satuan: '',
        label: str,
      };
      setInput(newInput);
    }
    dispatch({ type: 'UPDATE_BARANG_KELUAR_LIST', data: newInput });
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Kode Material"
          variant="outlined"
          size="small"
          variant="standard"
          type="number"
          disabled
          value={input.partCode || ''}
          fullWidth
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dataMaterial}
          // sx={{ width: 300 }}
          size="small"
          value={input.label}
          onChange={(event, newValue) => {
            // handleClickMaterial(newValue);
            console.log(newValue);
          }}
          inputValue={input.label}
          onInputChange={(event, newInputValue) => {
            // setInputValue(newInputValue);
            // console.log(newInputValue);
            handleClickMaterial(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label="Material" variant="standard" />}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>{input.stockAkhir || '-'}</TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Jumlah"
          variant="outlined"
          size="small"
          type="number"
          variant="standard"
          value={input.qty || ''}
          onChange={(e) => handleChange('qty', e)}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Keterangan"
          variant="outlined"
          size="small"
          variant="standard"
          value={input.note || ''}
          onChange={(e) => handleChange('note', e)}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default BarangKeluarInventoryRowComponent;
