import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
import moment from 'moment';

// ----------------------------------------------------------------------

const stockkeluar = [...Array(20)].map((_, index) => ({
  id: faker.datatype.uuid(),
  kodeMaterial: moment().valueOf(),
  namaMaterial: sample(['Pensil', 'HVS', 'Bulpoin']),
  keterangan: 'ini keterangannya',
  warehouse: sample(['KM 6', 'KM 68']),
  merek: sample(['cap jempol', 'cap plang']),
  type: sample(['asset', 'inventory']),
  quantity: sample([1, 2, 3, 4, 5]),
  satuan: sample(['PCS', 'BOX', 'RIM']),
  request: sample(['Irfan', 'Udin']),
  departement: sample(['Plant', 'HR']),
  tanggal: sample(['10-10-2023', '09-10-2023']),
}));

export default stockkeluar;
