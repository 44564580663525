import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Auth = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  const checkUserToken = () => {
    const nikLogin = localStorage.getItem('nik');
    const namaLogin = localStorage.getItem('nama');
    if (!nikLogin) {
      setIsLoggedIn(false);
      navigate('/');
    } else {
      dispatch({ type: 'NEW_NIK', data: nikLogin });
      dispatch({ type: 'NEW_NAMA', data: namaLogin });
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkUserToken();
  }, []);

  return <>{isLoggedIn ? props.children : null}</>;
};

export default Auth;
