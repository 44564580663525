import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Guest = (props) => {
  const navigate = useNavigate();
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const checkUserToken = () => {
    const nikLogin = localStorage.getItem('nik');
    if (nikLogin) {
      setIsLoggedOut(false);
      navigate('/dashboard');
    } else {
      setIsLoggedOut(true);
    }
  };

  useEffect(() => {
    checkUserToken();
  }, []);

  return <>{isLoggedOut ? props.children : null}</>;
};

export default Guest;
