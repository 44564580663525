import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Button,
  Grid,
  IconButton,
  TableFooter,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import SaveIcon from '@mui/icons-material/Save';
import BarangKeluarAssetRowComponent from '../../../../components/BarangKeluarAssetRowComponent';
import Iconify from '../../../../components/iconify';
import { createBarangKeluar } from '../../../../utils/api';

function FormAsset() {
  const dispatch = useDispatch();
  const drow = useSelector((state) => state.barangKeluarList);
  const barangKeluarList = useSelector((state) => state.barangKeluarList);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const handleAddRow = () => {
    const noID = moment().valueOf();
    dispatch({
      type: 'ADD_BARANG_KELUAR_LIST',
      data: { id: noID, dataAssigned: moment().format('YYYY-MM-DD'), qty: 1 },
    });
  };
  const [input, setInput] = useState('');

  const handleChange = (name, e) => {
    setInput({ ...input, [name]: e.target.value });
  };

  const handleMessage = (str) => {
    setMessage(str);
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!input) {
      handleMessage('Request dan Departement belum diisi');
      setLoading(false);
      return;
    }
    const json = {
      tanggal: moment().format('YYYY-MM-DD'),
      request: input.request || '',
      departement: input.departement || '',
      spesifikasi: 'Asset',
      barang: barangKeluarList,
    };
    createBarangKeluar(json)
      .then((result) => {
        console.log(result);
        console.log(result.status);
        if (result.status === 200) {
          dispatch({ type: 'REMOVE_BARANG_KELUAR_LIST' });
          setInput('');
          handleMessage('Berhasil Menambah Request Order');
        } else {
          handleMessage('Gagal Menambah Request Order');
        }
        setLoading(false);
      })
      .catch((err) => {
        handleMessage('Kesalahan server, silahkan ulangi lagi');
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
        <Table stickyHeader sx={{ minWidth: 1500, marginBottom: 2 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="25%">Asset Information</TableCell>
              <TableCell width="25%">Asset Procurement</TableCell>
              <TableCell width="25%">Asset Deployment</TableCell>
              <TableCell width="25%">Asset Utilization & Retirement</TableCell>
              {/* <TableCell width="15%" align="left">
                Keterangan
              </TableCell> */}
              <TableCell width="5%" align="right">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drow.map((item, index) => {
              return <BarangKeluarAssetRowComponent {...item} key={index} />;
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} align="right">
                Tambah Material
              </TableCell>
              <TableCell align="right">
                <IconButton color="primary" onClick={handleAddRow}>
                  <Iconify icon="eva:plus-fill" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Request"
          variant="outlined"
          size="small"
          sx={{ mr: 2 }}
          value={input.request || ''}
          onChange={(e) => handleChange('request', e)}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          label="Departemen"
          variant="outlined"
          size="small"
          sx={{ mr: 2 }}
          value={input.departement || ''}
          onChange={(e) => handleChange('departement', e)}
        />
        <div>
          <Button variant="contained" onClick={handleSubmit} disabled={loading}>
            {!loading && (
              <>
                <SaveIcon sx={{ mr: 1 }} /> Simpan
              </>
            )}
            {loading && <CircularProgress color="inherit" size={24} />}
          </Button>
        </div>
      </Grid>
      {message}
    </div>
  );
}

export default FormAsset;
