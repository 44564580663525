import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Grid, Stack, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import Iconify from '../../../components/iconify';

function Form({ button, data = [], handleClose }) {
  const [input, setInput] = useState(data);
  const [danger, setDanger] = useState('');
  console.log(data);
  const dispatch = useDispatch();

  const handleChange = (e, name) => {
    setInput({ ...input, [name]: e.target.value });
  };

  const handleDanger = (message) => {
    setDanger(message);
    setTimeout(() => {
      setDanger('');
    }, 3000);
  };

  const handleSubmit = () => {
    if (button === 'Tambah') {
      axios
        .post(`${process.env.REACT_APP_API}/warehouse`, input)
        .then((result) => {
          console.log(result.data);
          const ress = result.data.data.data;
          console.log(ress);
          dispatch({ type: 'ADD_WAREHOUSE', data: { ...input, id: ress.insertId } });
          handleClose();
        })
        .catch((err) => {
          console.log(err.response.status);
          handleDanger('Kesalahan server atau data sudah ada');
        });
    } else if (button === 'Edit') {
      axios
        .patch(`${process.env.REACT_APP_API}/warehouse/${input.id}`, input)
        .then((result) => {
          console.log(result.data);
          // const ress = result.data.data;
          console.log(input);
          dispatch({ type: 'UPDATE_WAREHOUSE', data: input });
          handleClose();
        })
        .catch((err) => {
          console.log(err.response.status);
          handleDanger('Kesalahan server atau data sudah ada');
        });
    } else if (button === 'Hapus') {
      axios
        .delete(`${process.env.REACT_APP_API}/warehouse/${input.id}`)
        .then((result) => {
          console.log(result.data);
          // const ress = result.data.data;
          console.log(input);
          dispatch({ type: 'DELETE_WAREHOUSE', data: input });
          handleClose();
        })
        .catch((err) => {
          console.log(err.response.status);
          handleDanger('Kesalahan server atau data sudah ada');
        });
    }
  };

  return (
    <Stack>
      {button === 'Hapus' && (
        <Typography id="modal-modal-description" sx={{ mb: 2, textAlign: 'center' }}>
          Anda yakin ingin menghapus data tersebut
        </Typography>
      )}
      {button !== 'Hapus' && (
        <>
          <TextField
            sx={{ marginBottom: 2 }}
            id="outlined-basic"
            label="Nama Lokasi"
            variant="outlined"
            value={input.namaLokasi || ''}
            onChange={(e) => handleChange(e, 'namaLokasi')}
          />
        </>
      )}
      <Stack direction="row" justifyContent="center" alignItems="center">
        {button === 'Tambah' && (
          <Button variant="contained" startIcon={<Iconify icon="eva:save-fill" />} size="large" onClick={handleSubmit}>
            Simpan
          </Button>
        )}
        {button === 'Edit' && (
          <Button
            variant="contained"
            color="success"
            startIcon={<Iconify icon="eva:edit-2-fill" />}
            onClick={handleSubmit}
            size="large"
          >
            Simpan Perubahan
          </Button>
        )}
        {button === 'Hapus' && (
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:edit-2-fill" />}
            onClick={handleSubmit}
            size="large"
          >
            Ya Hapus
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export default Form;
