import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';

function BarangMasukLocalRowComponent(data) {
  const material = useSelector((e) => e.material);
  const [dataMaterial, setDataMaterial] = useState([]);
  const orderMasuk = useSelector((state) => state.barangMasukOrder);
  const [input, setInput] = useState(orderMasuk);

  useEffect(() => {
    const find = orderMasuk.find((e) => e.id === data.id);
    setInput(find);
  }, [data, orderMasuk]);

  const handleChange = (name, e) => {
    const newInput = { ...input, [name]: e.target.value };
    setInput(newInput);
    dispatch({ type: 'UPDATE_BARANGMASUK_ORDER', data: newInput });
  };

  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch({ type: 'REMOVE_BARANGMASUK_ORDER_BY_ID', id: data.id });
  };

  useEffect(() => {
    const updatedDataMaterial = material.map((item) => ({
      ...item,
      label: `${item.partCode} ${item.deskripsi} ${item.merk}`,
    }));
    setDataMaterial(updatedDataMaterial);
  }, []);

  const handleClickMaterial = (str) => {
    let newInput = input;
    console.log('ini strnya');
    console.log(str);
    if (str) {
      const s = dataMaterial.find((e) => e.label.toLowerCase().match(str.toLowerCase()));
      if (s) {
        newInput = {
          ...input,
          partCode: s.partCode,
          deskripsi: s.deskripsi,
          spesifikasi: s.spesifikasi,
          satuan: s.sat,
          label: str,
        };
      } else {
        newInput = {
          ...input,
          partCode: '',
          deskripsi: str,
          label: str,
          satuan: '',
        };
      }
      setInput(newInput);
    } else {
      newInput = {
        ...input,
        partCode: '',
        deskripsi: '',
        spesifikasi: '',
        satuan: '',
        label: '',
      };
      setInput(newInput);
    }
    dispatch({ type: 'UPDATE_BARANGMASUK_ORDER', data: newInput });
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Kode Material"
          variant="outlined"
          size="small"
          variant="standard"
          type="number"
          value={input.partCode || ''}
          onChange={(e) => handleChange('partCode', e)}
          fullWidth
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dataMaterial}
          freeSolo
          // sx={{ width: 300 }}
          size="small"
          value={input.label}
          onChange={(event, newValue) => {
            // handleClickMaterial(newValue);
            // console.log(newValue);
            if (newValue) {
              handleClickMaterial(newValue.deskripsi);
            } else {
              handleClickMaterial();
            }
          }}
          inputValue={input.label}
          onInputChange={(event, newInputValue) => {
            // setInputValue(newInputValue);
            // console.log(newInputValue);
            const newInput = { ...input, deskripsi: newInputValue, label: newInputValue };
            setInput(newInput);
            dispatch({ type: 'UPDATE_BARANGMASUK_ORDER', data: newInput });
          }}
          renderInput={(params) => <TextField {...params} label="Material" variant="standard" />}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <FormControl fullWidth sx={{ paddingTop: 0 }} size="small" variant="standard">
          <InputLabel id="demo-simple-select-label">Spesifikasi</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input.spesifikasi || ''}
            label="Spesifikasi"
            onChange={(e) => handleChange('spesifikasi', e)}
          >
            <MenuItem value="Inventory">Inventory</MenuItem>
            <MenuItem value="Asset">Asset</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Jumlah"
          variant="outlined"
          size="small"
          type="number"
          variant="standard"
          value={input.qty || ''}
          onChange={(e) => handleChange('qty', e)}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <FormControl fullWidth sx={{ paddingTop: 0 }} size="small" variant="standard">
          <InputLabel id="demo-simple-select-label">Satuan</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input.satuan || ''}
            label="Satuan"
            onChange={(e) => handleChange('satuan', e)}
          >
            <MenuItem value="Pcs">PCS</MenuItem>
            <MenuItem value="Pack">PACK</MenuItem>
            <MenuItem value="Lbr">LEBAR</MenuItem>
            <MenuItem value="Kotak">KOTAK</MenuItem>
            <MenuItem value="Buku">BUKU</MenuItem>
            <MenuItem value="rim">RIM</MenuItem>
            <MenuItem value="Pail">PAIL</MenuItem>
            <MenuItem value="Set">SET</MenuItem>
            <MenuItem value="Btl">BOTOL</MenuItem>
            <MenuItem value="Bks">BKS</MenuItem>
            <MenuItem value="Mtr">MTR</MenuItem>
            <MenuItem value="Roll">ROLL</MenuItem>
            <MenuItem value="Box">BOX</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Keterangan"
          variant="outlined"
          size="small"
          variant="standard"
          value={input.note || ''}
          onChange={(e) => handleChange('note', e)}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default BarangMasukLocalRowComponent;
