import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { sentenceCase } from 'change-case';

import Iconify from '../../../components/iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const Form = ({ id, label, type, item, value }) => {
  switch (type) {
    case 'select':
      return (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" label={label}>
            {item.map((d, index) => {
              return (
                <MenuItem value={d.id || ''} key={index}>
                  {d.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    case 'text':
      return <TextField sx={{ marginBottom: 2 }} id="outlined-basic" label={label} variant="outlined" />;
    case 'number':
      return <TextField sx={{ marginBottom: 2 }} id="outlined-basic" label={label} variant="outlined" />;
    case 'monthyear':
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker sx={{ width: '100%', marginBottom: 2 }} label={label} views={['month', 'year']} size="small" />
          </DemoContainer>
        </LocalizationProvider>
      );
    case 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker label={label} sx={{ width: '100%', marginBottom: 2 }} />
          </DemoContainer>
        </LocalizationProvider>
      );
    default:
      return null;
  }
};

export default function AppBasicModal({ title, form = [], button, description = '' }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      {button === 'Tambah' && (
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          size="large"
          sx={{ marginTop: 1, marginLeft: 5 }}
          onClick={handleOpen}
        >
          Tambah
        </Button>
      )}
      {button === 'Edit' && (
        <MenuItem onClick={handleOpen}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
      )}
      {button === 'Hapus' && (
        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpen}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Hapus
        </MenuItem>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack alignItems="center" justifyContent="space-between" direction="row" sx={{ paddingBottom: 2 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {sentenceCase(title)}
            </Typography>
            <IconButton variant="contained" onClick={handleClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Stack>
          {description && (
            <Typography id="modal-modal-description" sx={{ mb: 2, textAlign: 'center' }}>
              {description}
            </Typography>
          )}
          <Stack>
            {form.map((item, index) => {
              return <Form {...item} key={index} />;
            })}
            {/* <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="demo-simple-select-label">Barang/Material</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Barang/Material">
                <MenuItem value="Kuri">Kursi</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="demo-simple-select-label">Warehouse/Gudang</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Warehouse/Gudang">
                <MenuItem value="KM6">KM 6</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ marginBottom: 2 }}
              id="outlined-basic"
              label="Stock Masuk"
              variant="outlined"
              type="number"
            />
            <TextField sx={{ marginBottom: 2 }} id="outlined-basic" label="Stock Keluar" variant="outlined" />
            <TextField sx={{ marginBottom: 2 }} id="outlined-basic" label="Stock Awal" variant="outlined" />
            <TextField sx={{ marginBottom: 2 }} id="outlined-basic" label="Stock Akhir" variant="outlined" />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  sx={{ width: '100%', marginBottom: 2 }}
                  label={'"month" and "year"'}
                  views={['month', 'year']}
                  size="small"
                />
              </DemoContainer>
            </LocalizationProvider>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="demo-simple-select-label">Tipe</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Tipe">
                <MenuItem value="Inventory">Asset</MenuItem>
                <MenuItem value="Inventory">Inventory</MenuItem>
              </Select>
            </FormControl> */}
            <Stack direction="row" justifyContent="center" alignItems="center">
              {button === 'Tambah' && (
                <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} size="large">
                  Simpan
                </Button>
              )}
              {button === 'Edit' && (
                <Button variant="contained" color="success" startIcon={<Iconify icon="eva:edit-2-fill" />} size="large">
                  Simpan Perubahan
                </Button>
              )}
              {button === 'Hapus' && (
                <Button variant="contained" color="error" startIcon={<Iconify icon="eva:edit-2-fill" />} size="large">
                  Ya Hapus
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
