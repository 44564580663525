import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateField } from '@mui/x-date-pickers/DateField';

import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
];

function SOComponent(data) {
  const [age, setAge] = useState('');
  const handleChange = (e) => {
    setAge(e.target.value);
  };
  const dispatch = useDispatch();
  const handleDelete = () => {
    console.log(data);
    dispatch({ type: 'REMOVE_STOCK_KELUAR_BY_ID', id: data.id });
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: 0.5 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={top100Films}
          size="small"
          renderInput={(params) => <TextField {...params} label="Nama Material" variant="standard" />}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Keterangan"
          variant="outlined"
          size="small"
          variant="standard"
          fullWidth
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <FormControl fullWidth sx={{ paddingTop: 0 }} size="small" variant="standard">
          <InputLabel id="demo-simple-select-label">Warehouse</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value="KM6">KM 6</MenuItem>
            <MenuItem value="KM68">KM 68</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField id="outlined-basic" label="Merek" variant="outlined" size="small" variant="standard" fullWidth />
      </TableCell>
      {/* <TableCell sx={{ padding: 0.5 }}>
        <FormControl fullWidth sx={{ paddingTop: 0 }} size="small" variant="standard">
          <InputLabel id="demo-simple-select-label">Tipe</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value="Inventory">Inventory</MenuItem>
            <MenuItem value="Asset">Asset</MenuItem>
          </Select>
        </FormControl>
      </TableCell> */}
      <TableCell sx={{ padding: 0.5 }}>
        <TextField id="outlined-basic" label="Tipe" variant="outlined" size="small" type="number" variant="standard" />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField id="outlined-basic" label="Qty" variant="outlined" size="small" type="number" variant="standard" />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <FormControl fullWidth sx={{ paddingTop: 0 }} size="small" variant="standard">
          <InputLabel id="demo-simple-select-label">Satuan</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value="PCS">PCS</MenuItem>
            <MenuItem value="DUS">DUS</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField id="outlined-basic" label="Request" variant="outlined" size="small" variant="standard" />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField id="outlined-basic" label="Departement" variant="outlined" size="small" variant="standard" />
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker label={'Tanggal'} size="small" variant="standard" />
          </DemoContainer>
        </LocalizationProvider>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateField']}>
            <DateField label="Basic date field" variant="standard" />
          </DemoContainer>
        </LocalizationProvider> */}
      </TableCell>
      <TableCell align="right">
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default SOComponent;
