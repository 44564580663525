import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API, // Ganti dengan base URL Anda
  headers: {
    'Content-Type': 'application/json',
  },
});

export const createBarangRo = (json) => axiosInstance.post(`/barangro`, json);
export const getRequestOrderList = () => axiosInstance.get(`/requestorder`);
export const getRequestOrderProses = () => axiosInstance.get(`/requestorderproses`);
export const getRequestOrderProsesList = (noRo) => axiosInstance.get(`/requestorderproses/${noRo}`);

export const createBarangMasuk = (json) => axiosInstance.post(`/barangmasuk`, json);
export const createBarangMasukRo = (json) => axiosInstance.post(`/barangmasukro`, json);
export const getBarangMasuk = () => axiosInstance.get(`/barangmasuk`);

export const getInventory = () => axiosInstance.get(`/inventory`);
export const getAsset = () => axiosInstance.get(`/asset`);
export const editAsset = (json, id) => axiosInstance.patch(`/asset/${id}`, json);

export const getBarangKeluarInventory = () => axiosInstance.get(`barangkeluar/Inventory`);
export const getBarangKeluarAsset = () => axiosInstance.get(`barangkeluar/Asset`);
export const createBarangKeluar = (json) => axiosInstance.post(`barangkeluar`, json);

export const getBarangKeluarMaterialInventory = () => axiosInstance.get(`inventorymaterial/Inventory`);
export const getBarangKeluarMaterialAsset = () => axiosInstance.get(`inventorymaterial/Asset`);

export const getBarangKeluarCategory = () => axiosInstance.get(`datastaticassetcategoy`);
export const getBarangKeluarProcerumentDeployment = () => axiosInstance.get(`datastaticassetprocerumentdeployment`);
export const getBarangKeluarEmployeeDivision = () => axiosInstance.get(`datastaticassetemployeedivision`);
export const getBarangKeluarEmployeePosition = () => axiosInstance.get(`datastaticassetemployeeposition`);
export const getBarangKeluarOfficeEquipment = () => axiosInstance.get(`datastaticassetofficequipment`);
export const getAssetCondition = () => axiosInstance.get(`datastaticassetcondition`);
export const updateAssetCondition = (json, id) => axiosInstance.patch(`assetstatus/${id}`, json);

export const getTotalBarangKeluar = () => axiosInstance.get(`totalbarangkeluar`);
export const getTotalBarangMasuk = () => axiosInstance.get(`totalbarangmasuk`);
export const getTotalMinimumStock = () => axiosInstance.get(`totalbarangminimumstock`);
export const getTotalOutstandingStock = () => axiosInstance.get(`totalbarangoutstandingstock`);
export const getBarangMinimumStock = () => axiosInstance.get(`barangminimumstock`);
export const getBarangOutstandingStock = () => axiosInstance.get(`barangoutstandingtock`);

export const login = (json) => axiosInstance.post(`auth/login`, json);
export const register = (json) => axiosInstance.post(`auth/register`, json);
