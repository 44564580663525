import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
// components
// import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ListToolbar, ListHead } from '../../sections/@dashboard/listHead';
import { AppBasicModal } from '../../sections/@dashboard/app';
// mock
// import ASSETLIST from '../../_mock/asset';
import {
  getAsset,
  getAssetCondition,
  getBarangKeluarCategory,
  getBarangKeluarEmployeeDivision,
  getBarangKeluarEmployeePosition,
  getBarangKeluarMaterialAsset,
  getBarangKeluarOfficeEquipment,
  getBarangKeluarProcerumentDeployment,
  updateAssetCondition,
} from '../../utils/api';
import FormAsset from './components/FormAsset';
import AppModal from '../../sections/@dashboard/app/AppModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'id', alignRight: false },
  { id: 'deskripsi', label: 'Deskripsi', alignRight: false },
  { id: 'serialNumber', label: 'Serial Number', alignRight: false },
  { id: 'assetCategory', label: 'Asset Category', alignRight: false },
  { id: 'assetSubCategory', label: 'Asset Sub-Category', alignRight: false },
  { id: 'assetSerialNumber', label: 'Asset Serial Number', alignRight: false },
  { id: 'brand', label: 'Brand', alignRight: false },
  { id: 'model', label: 'Model', alignRight: false },
  { id: 'spesification', label: 'Spesification', alignRight: false },
  { id: 'accessories', label: 'Accessories', alignRight: false },
  { id: 'locationSite', label: 'Location Site', alignRight: false },
  { id: 'locationArea', label: 'Location Area', alignRight: false },
  { id: 'locationNumber', label: 'Location Number', alignRight: false },
  { id: 'dateAssigned', label: 'Date Assigned', alignRight: false },
  { id: 'employeeDivision', label: 'Employee Division', alignRight: false },
  { id: 'codeNumber', label: 'Code Number', alignRight: false },
  { id: 'serialNumberDeployment', label: 'Serial Number Deployment', alignRight: false },
  { id: 'lifetimeTargetInYear', label: 'Lifetime Target In Year', alignRight: false },
  { id: 'lifetimeArchivementInYear', label: 'Lifetime Archivement in Year', alignRight: false },
  { id: 'utilizationInPercentage', label: 'Utilization In Percentage', alignRight: false },
  { id: 'availableUtilizationInPercentage', label: 'Available Utilization in Percentage', alignRight: false },
  { id: 'minimumSalvageValue', label: 'Minimum Salvage Value', alignRight: false },
  { id: 'salvageValue', label: 'Salvage Value', alignRight: false },
  { id: 'note', label: 'Note', alignRight: false },
  { id: 'status', label: 'Status' },
  { id: '', label: 'Aksi' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.material.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Asset() {
  const ASSETLIST = useSelector((state) => state.asset);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('material');

  const [filterMaterial, setFilterMaterial] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const condition = useSelector((state) => state.condition);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEdit = (rowClick) => {
    return <FormAsset button="Edit" {...rowClick} onButtonClick={() => getData()} />;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ASSETLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByMaterial = (event) => {
    setPage(0);
    setFilterMaterial(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ASSETLIST.length) : 0;

  const filteredInventory = applySortFilter(ASSETLIST, getComparator(order, orderBy), filterMaterial);

  const isNotFound = !filteredInventory.length && !!filterMaterial;

  const getData = () => {
    getAsset()
      .then((result) => {
        const s = result.data.data;
        dispatch({ type: 'NEW_ASSET', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCondition = () => {
    getAssetCondition()
      .then((result) => {
        const s = result.data.data;
        dispatch({ type: 'NEW_BARANG_CONDITION', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDataBarangKeluarMaterial = () => {
    getBarangKeluarMaterialAsset()
      .then((result) => {
        const s = result.data.data;
        dispatch({ type: 'NEW_BARANG_KELUAR_MATERIAL', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategory = () => {
    getBarangKeluarCategory()
      .then((result) => {
        const s = result.data.data;
        console.log(s);
        const newData = s.map(({ id, assetSubCategory, lifeTimeTarget }) => ({
          id,
          assetSubCategory,
          lifeTimeTarget,
          label: `${id} ${assetSubCategory}`,
        }));
        dispatch({ type: 'NEW_BARANG_KELUAR_CATEGORY', data: newData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createUniqueKilometers = (arr) => {
    if (!Array.isArray(arr)) {
      return [];
    }

    const unique = arr.reduce((kilometers, item) => {
      if (!kilometers.includes(item.kilometer)) {
        kilometers.push(item.kilometer);
      }
      dispatch({ type: 'NEW_BARANG_KELUAR_LOCATION_SITE', data: kilometers });
      return kilometers;
    }, []);

    const mappedUnique = unique.map((kilometer) => ({ kilometer }));
    return mappedUnique;
  };

  const getProcerumentDeployment = () => {
    getBarangKeluarProcerumentDeployment()
      .then((result) => {
        const s = result.data.data;
        console.log(s);
        dispatch({ type: 'NEW_BARANG_KELUAR_PROCERUMENT_DEPLOYMENT', data: s });
        createUniqueKilometers(s);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEmployeeDivision = () => {
    getBarangKeluarEmployeeDivision()
      .then((result) => {
        const s = result.data.data;
        console.log(s);
        dispatch({ type: 'NEW_BARANG_KELUAR_EMPLOYEE_DIVISION', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEmployeePosition = () => {
    getBarangKeluarEmployeePosition()
      .then((result) => {
        const s = result.data.data;
        console.log(s);
        dispatch({ type: 'NEW_BARANG_KELUAR_EMPLOYEE_POSITION', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOfficeEquipment = () => {
    getBarangKeluarOfficeEquipment()
      .then((result) => {
        const s = result.data.data;
        console.log(s);
        dispatch({ type: 'NEW_BARANG_KELUAR_OFFICE_EQUIPMENT', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDataBarangKeluarMaterial();
    getProcerumentDeployment();
    getCategory();
    getEmployeeDivision();
    getEmployeePosition();
    getOfficeEquipment();
    getData();
    getCondition();
  }, []);

  const handleChangeStatus = (stt, id) => {
    console.log(stt, id);
    const json = { status: stt };
    updateAssetCondition(json, id)
      .then((result) => {
        console.log(result.data);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container maxWidth="100%">
        <Helmet>
          <title> Asset </title>
        </Helmet>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Asset
          </Typography>
          <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label={'"month" and "year"'} views={['month', 'year']} size="small" />
              </DemoContainer>
            </LocalizationProvider>
            <Button
              variant="contained"
              color="success"
              startIcon={<Iconify icon="eva:plus-fill" />}
              size="large"
              sx={{ marginTop: 1, marginLeft: 2 }}
            >
              Export
            </Button> */}
            {/* <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              size="large"
              sx={{ marginTop: 1, marginLeft: 5 }}
            >
              Tambah
            </Button> */}
            {/* <AppBasicModal title="Tambah Data" form={form} button="Tambah" /> */}
          </Grid>
        </Stack>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterMaterial={filterMaterial}
            onFilterMaterial={handleFilterByMaterial}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={ASSETLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredInventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      deskripsi,
                      serialNumber,
                      assetCategory,
                      assetSubCategory,
                      assetSerialNumber,
                      brand,
                      model,
                      spesification,
                      accessories,
                      locationSite,
                      locationArea,
                      locationNumber,
                      dateAssigned,
                      employeeDivision,
                      codeNumber,
                      serialNumberDeployment,
                      lifetimeTargetInYear,
                      lifetimeArchivementInYear,
                      utilizationInPercentage,
                      availableUtilizationInPercentage,
                      minimumSalvageValue,
                      salvageValue,
                      note,
                      status,
                    } = row;
                    const selectedInventory = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedInventory}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedInventory} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {deskripsi}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {serialNumber}
                        </TableCell>
                        <TableCell align="left">{assetCategory}</TableCell>
                        <TableCell align="left">{assetSubCategory}</TableCell>
                        <TableCell align="left">{assetSerialNumber}</TableCell>
                        <TableCell align="left">{brand}</TableCell>
                        <TableCell align="left">{model}</TableCell>
                        <TableCell align="left">{spesification}</TableCell>
                        <TableCell align="left">{accessories}</TableCell>
                        <TableCell align="left">{locationSite}</TableCell>
                        <TableCell align="left">{locationArea}</TableCell>
                        <TableCell align="left">{locationNumber}</TableCell>
                        <TableCell align="left">{dateAssigned}</TableCell>
                        <TableCell align="left">{employeeDivision}</TableCell>
                        <TableCell align="left">{codeNumber}</TableCell>
                        <TableCell align="left">{serialNumberDeployment}</TableCell>
                        <TableCell align="left">{lifetimeTargetInYear}</TableCell>
                        <TableCell align="left">{lifetimeArchivementInYear}</TableCell>
                        <TableCell align="left">{utilizationInPercentage}</TableCell>
                        <TableCell align="left">{availableUtilizationInPercentage}</TableCell>
                        <TableCell align="left">{minimumSalvageValue}</TableCell>
                        <TableCell align="left">{salvageValue}</TableCell>
                        <TableCell align="left">{note}</TableCell>
                        <TableCell align="left">
                          <FormControl fullWidth size="small" sx={{ width: 150 }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={status || ''}
                              label="Status"
                              onChange={(e) => handleChangeStatus(e.target.value, id)}
                            >
                              {condition.map((item, index) => (
                                <MenuItem key={index} value={item.assetCondition}>
                                  {item.assetCondition}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell align="right">
                          <AppModal
                            title="Custom"
                            form={() => handleEdit(row)}
                            button="IconButton"
                            iconButton={<EditIcon />}
                          />
                          {/* <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ada hasil untuk &nbsp;
                            <strong>&quot;{filterMaterial}&quot;</strong>.
                            <br /> Silahkan coba lagi dengan menggunakan kata yang berbeda.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={ASSETLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <AppBasicModal title="Edit" form={[]} button="Edit" description="" />
          {/* <AppBasicModal title="Hapus" form={[]} button="Hapus" description="Hapus Inventory dengan kode JGG767" /> */}

          {/* <MenuItem>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem> */}
          {/* <MenuItem sx={{ color: 'error.main' }}>
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem> */}
        </Popover>
      </Container>
    </>
  );
}
