import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

function BarangKeluarAssetRowComponent(data) {
  const material = useSelector((state) => state.barangKeluarMaterial);
  const assetProcerumentDeployment = useSelector((state) => state.assetProcerumentDeployment);
  const assetCategory = useSelector((state) => state.assetCategory);
  const assetEmployeeDivision = useSelector((state) => state.assetEmployeeDivision);
  const assetEmployeePosition = useSelector((state) => state.assetEmployeePosition);
  const assetOfficeEquipment = useSelector((state) => state.assetOfficeEquipment);
  const [dataMaterial, setDataMaterial] = useState([]);
  const barangKeluar = useSelector((state) => state.barangKeluarList);
  const [input, setInput] = useState(barangKeluar);
  const dispatch = useDispatch();

  const locationSite = useSelector((state) => state.locationSite);
  const [locationArea, setLocationArea] = useState([]);

  useEffect(() => {
    const find = barangKeluar.find((e) => e.id === data.id);
    setInput(find);
  }, [data, barangKeluar]);

  const handleChange = (name, e) => {
    const val = e.target.value;
    console.log(name);
    let newInput = { ...input, [name]: val };
    if (name === 'employeeDivision') {
      newInput = generateSNDeployment(name, val, newInput);
    }
    setInput(newInput);
    dispatch({ type: 'UPDATE_BARANG_KELUAR_LIST', data: newInput, id: input.id });
    if (name === 'locationSite') {
      changeLocationArea(assetProcerumentDeployment, val);
    }
  };

  const generateSNDeployment = (name, value, oldInput) => {
    let codeNumber = oldInput.codeNumber ? `-${oldInput.codeNumber}` : '';
    let locationNumber = oldInput.locationNumber ? `-${oldInput.locationNumber}` : '';
    let employeeDivision = oldInput.employeeDivision ? `-${oldInput.employeeDivision}` : '';

    if (name === 'codeNumber') codeNumber = value ? `-${value}` : '';
    if (name === 'locationNumber') locationNumber = value ? `-${value}` : '';
    if (name === 'employeeDivision') employeeDivision = value ? `-${value}` : '';

    const newSN = `GA${locationNumber}${employeeDivision}${codeNumber}`;
    console.log(newSN);
    return { ...oldInput, serialNumberDeployment: newSN };
  };

  const changeLocationArea = (dataArray, val) => {
    const filteredData = dataArray.filter((item) => item.kilometer === val);
    const newFilteredData = filteredData.map(({ detailCode, detailname, code }) => ({
      detailCode,
      detailname,
      code,
      label: detailname,
    }));
    console.log(newFilteredData);
    setLocationArea(newFilteredData);
    return newFilteredData;
  };

  const handleDelete = () => {
    dispatch({ type: 'REMOVE_BARANG_KELUAR_LIST_BY_ID', id: input.id });
  };

  useEffect(() => {
    const updatedDataMaterial = material.map((item) => ({
      ...item,
      label: `${item.id}-${item.partCode} ${item.deskripsi} ${item.merk || ''}${
        item.noGr ? ` NoGR: ${item.noGr}` : ''
      }`,
    }));
    setDataMaterial(updatedDataMaterial);
  }, []);

  const handleClickMaterial = (str) => {
    let newInput = input;
    const idInput = input.id;
    if (str) {
      const s = dataMaterial.find((e) => e.label.toLowerCase().match(str.toLowerCase()));
      if (s) {
        newInput = {
          ...input,
          id: s.id,
          accessories: s.accessories,
          assetCategory: s.assetCategory,
          assetSerialNumber: s.serialNumber,
          assetSubCategory: s.assetSubCategory,
          availableUtilizationPercent: s.availableUtilizationPercent,
          brand: s.brand,
          codeNumber: s.codeNumber,
          dataAssigned: input.dataAssigned,
          deskripsi: s.deskripsi,
          employeeDivision: input.employeeDivision,
          label: s.label,
          lifetimeAchivement: s.lifetimeAchivement,
          lifetimeTarget: s.lifetimeTarget,
          locationArea: input.locationArea,
          locationNumber: input.locationNumber,
          locationSite: input.locationSite,
          minimunSalvageValue: s.minimunSalvageValue,
          model: s.model,
          noGr: s.noGr,
          note: input.note,
          partCode: s.partCode,
          salvageValue: input.salvageValue,
          serialNumber: s.serialNumber,
          serialNumberDeployment: s.serialNumberDeployment,
          spesification: s.spesification,
          status: s.status,
          utilizationPercent: s.utilizationPercent,
        };
        newInput = generateSNDeployment('codeNumber', s.codeNumber, newInput);
      } else {
        newInput = { ...input, deskripsi: str };
      }
      setInput(newInput);
    } else {
      const newId = moment().valueOf();
      newInput = {
        ...input,
        id: newId,
        accessories: '',
        assetCategory: '',
        assetSerialNumber: '',
        assetSubCategory: '',
        availableUtilizationPercent: '',
        brand: '',
        codeNumber: '',
        deskripsi: '',
        label: '',
        lifetimeAchivement: '',
        lifetimeTarget: '',
        minimunSalvageValue: '',
        model: '',
        noGr: '',
        partCode: '',
        serialNumber: '',
        serialNumberDeployment: '',
        spesification: '',
        status: '',
        utilizationPercent: '',
      };
      newInput = generateSNDeployment('codeNumber', '', newInput);
      setInput(newInput);
    }
    dispatch({ type: 'UPDATE_BARANG_KELUAR_LIST', data: newInput, id: idInput });
  };

  const handleClickLocationArea = (str) => {
    let newInput = input;
    const idInput = input.id;
    const location = locationArea;
    if (str) {
      console.log(str);
      // const s = location.find((e) => e.label.toLowerCase().match(str.toLowerCase()));
      const s = location.find((e) => e.code === str.code);
      if (s) {
        newInput = { ...input, locationArea: s.detailname, locationNumber: s.code };
        newInput = generateSNDeployment('locationNumber', s.code, newInput);
      } else {
        newInput = { ...input, locationArea: 'No Match Found', locationNumber: '' };
      }
      setInput(newInput);
    } else {
      newInput = {
        ...input,
        locationArea: '',
        locationNumber: '',
      };
      newInput = generateSNDeployment('locationNumber', '', newInput);
      setInput(newInput);
    }
    dispatch({ type: 'UPDATE_BARANG_KELUAR_LIST', data: newInput, id: idInput });
  };

  const handleClickCategory = (str) => {
    let newInput = input;
    const idInput = input.id;
    const category = assetCategory;
    if (str) {
      console.log(str);
      const s = category.find((e) => e.id === str.id);
      if (s) {
        newInput = { ...input, assetSubCategory: s.assetSubCategory, assetSerialNumber: s.id };
      } else {
        newInput = { ...input, assetSubCategory: 'No Match Found', assetSerialNumber: '' };
      }
      setInput(newInput);
    } else {
      newInput = {
        ...input,
        assetSubCategory: '',
        assetSerialNumber: '',
      };
      setInput(newInput);
    }
    dispatch({ type: 'UPDATE_BARANG_KELUAR_LIST', data: newInput, id: idInput });
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: 0.5, pt: 2, verticalAlign: 'top' }}>
        <TextField
          id="outlined-basic"
          label="Kode Material"
          variant="standard"
          size="small"
          type="number"
          disabled
          sx={{ mb: 1 }}
          value={input.partCode || ''}
          fullWidth
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dataMaterial}
          sx={{ mb: 1 }}
          size="small"
          value={input.label}
          onChange={(event, newValue) => {
            // handleClickMaterial(newValue);
            console.log(newValue);
          }}
          inputValue={input.label}
          onInputChange={(event, newInputValue) => {
            // setInputValue(newInputValue);
            // console.log(newInputValue);
            handleClickMaterial(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label="Material" variant="standard" />}
        />
        <TextField
          fullWidth
          sx={{ mb: 1 }}
          id="outlined-basic"
          label="Asset Serial Number"
          variant="standard"
          size="small"
          disabled={!input.partCode}
          value={input.assetSerialNumber || ''}
          onChange={(e) => handleChange('assetSerialNumber', e)}
        />
        {/* <TextField
          fullWidth
          sx={{ mb: 1 }}
          id="outlined-basic"
          label="Asset Category"
          variant="standard"
          size="small"
          type="number"
          value={input.assetCategory || ''}
          onChange={(e) => handleChange('assetCategory', e)}
        /> */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={assetCategory}
          sx={{ mb: 1 }}
          size="small"
          disabled={!input.partCode}
          value={input.assetSubCategory || ''}
          onChange={(event, newValue) => {
            // handleClickMaterial(newValue);
            console.log(newValue);
            handleClickCategory(newValue);
          }}
          inputValue={input.assetSubCategory || ''}
          onInputChange={(event, newInputValue) => {
            // setInputValue(newInputValue);
            // console.log(newInputValue);
            // handleClickLocationArea(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label="Asset Sub Category" variant="standard" />}
        />
        <TextField
          fullWidth
          sx={{ mb: 1 }}
          id="outlined-basic"
          label="Asset Serial Number"
          variant="standard"
          disabled
          size="small"
          value={input.assetSerialNumber || ''}
          onChange={(e) => handleChange('assetSerialNumber', e)}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5, pt: 2, verticalAlign: 'top' }}>
        <TextField
          fullWidth
          sx={{ mb: 1 }}
          id="outlined-basic"
          label="Brand"
          variant="standard"
          size="small"
          disabled={!input.partCode}
          value={input.brand || ''}
          onChange={(e) => handleChange('brand', e)}
        />
        <TextField
          fullWidth
          sx={{ mb: 1 }}
          id="outlined-basic"
          label="Model"
          variant="standard"
          size="small"
          disabled={!input.partCode}
          value={input.model || ''}
          onChange={(e) => handleChange('model', e)}
        />
        <TextField
          fullWidth
          sx={{ mb: 1 }}
          id="outlined-basic"
          label="Spesification"
          variant="standard"
          size="small"
          disabled={!input.partCode}
          value={input.spesification || ''}
          onChange={(e) => handleChange('spesification', e)}
        />
        <TextField
          fullWidth
          sx={{ mb: 1 }}
          id="outlined-basic"
          label="Accessories"
          variant="standard"
          size="small"
          disabled={!input.partCode}
          value={input.accessories || ''}
          onChange={(e) => handleChange('accessories', e)}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5, pt: 2, verticalAlign: 'top' }}>
        <FormControl fullWidth variant="standard" size="small">
          <InputLabel id="demo-simple-select-label">Location Site</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input.locationSite || ''}
            label="Location Site"
            sx={{ mb: 1 }}
            onChange={(e) => handleChange('locationSite', e)}
          >
            {locationSite.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={locationArea}
          sx={{ mb: 1 }}
          size="small"
          value={input.locationArea || ''}
          disabled={!input.locationSite}
          onChange={(event, newValue) => {
            // handleClickMaterial(newValue);
            console.log(newValue);
            handleClickLocationArea(newValue);
          }}
          inputValue={input.locationArea || ''}
          onInputChange={(event, newInputValue) => {
            // setInputValue(newInputValue);
            // console.log(newInputValue);
            // handleClickLocationArea(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label="Location Area" variant="standard" />}
        />
        <TextField
          id="outlined-basic"
          label="Location Number"
          variant="standard"
          size="small"
          fullWidth
          disabled
          sx={{ mb: 1 }}
          value={input.locationNumber || ''}
          onChange={(e) => handleChange('locationNumber', e)}
        />
        <TextField
          id="outlined-basic"
          label="Data Assigned"
          variant="standard"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          value={input.dataAssigned || ''}
          onChange={(e) => handleChange('dataAssigned', e)}
        />
        <FormControl fullWidth variant="standard" size="small">
          <InputLabel id="demo-simple-select-label">Employee Division</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input.employeeDivision || ''}
            label="Employee Division"
            sx={{ mb: 1 }}
            onChange={(e) => handleChange('employeeDivision', e)}
          >
            {assetEmployeeDivision.map((item, index) => (
              <MenuItem key={index} value={item.employeDivisionName}>
                {item.employeDivisionName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="outlined-basic"
          label="Employee Name"
          variant="standard"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          value={input.employeeName || ''}
          onChange={(e) => handleChange('employeeName', e)}
        />
        <FormControl fullWidth variant="standard" size="small">
          <InputLabel id="demo-simple-select-label">Employee Position</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input.employeePostionName || ''}
            label="Employee Position"
            sx={{ mb: 1 }}
            onChange={(e) => handleChange('employeePostionName', e)}
          >
            {assetEmployeePosition.map((item, index) => (
              <MenuItem key={index} value={item.employeePostionName}>
                {item.employeePostionName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="outlined-basic"
          label="Code Number"
          variant="standard"
          size="small"
          fullWidth
          disabled
          sx={{ mb: 1 }}
          value={input.codeNumber || ''}
          onChange={(e) => handleChange('codeNumber', e)}
        />
        <TextField
          id="outlined-basic"
          label="Serial Number Deployment"
          variant="standard"
          size="small"
          fullWidth
          disabled
          sx={{ mb: 1 }}
          value={input.serialNumberDeployment || ''}
        />
      </TableCell>
      <TableCell sx={{ padding: 0.5, pt: 2, verticalAlign: 'top' }}>
        <TextField
          id="outlined-basic"
          label="Lifetime Target in Years"
          variant="standard"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          disabled={!input.partCode}
          value={input.lifetimeTarget || ''}
          onChange={(e) => handleChange('lifetimeTarget', e)}
        />
        <TextField
          id="outlined-basic"
          label="Lifetime Aschivement in Years"
          variant="standard"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          disabled={!input.partCode}
          value={input.lifetimeAchivement || ''}
          onChange={(e) => handleChange('lifetimeAchivement', e)}
        />
        <TextField
          id="outlined-basic"
          label="Utilization in Percentage"
          variant="standard"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          disabled={!input.partCode}
          value={input.utilizationPercent || ''}
          onChange={(e) => handleChange('utilizationPercent', e)}
        />
        <TextField
          id="outlined-basic"
          label="Available Utilization in Percentage"
          variant="standard"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          disabled={!input.partCode}
          value={input.availableUtilizationPercent || ''}
          onChange={(e) => handleChange('availableUtilizationPercent', e)}
        />
        <TextField
          id="outlined-basic"
          label="Minimum Salvage Value"
          variant="standard"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          disabled={!input.partCode}
          value={input.minimunSalvageValue || ''}
          onChange={(e) => handleChange('minimunSalvageValue', e)}
        />
        <TextField
          id="outlined-basic"
          label="Salvage Value"
          variant="standard"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          disabled={!input.partCode}
          value={input.salvageValue || ''}
          onChange={(e) => handleChange('salvageValue', e)}
        />
      </TableCell>
      <TableCell align="right" sx={{ pt: 2, verticalAlign: 'top' }}>
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default BarangKeluarAssetRowComponent;
