import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { register } from '../../../utils/api';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const [input, setInput] = useState([]);
  const [message, setMessage] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    register(input)
      .then((result) => {
        const s = result.data;
        setMessage(s.message);
      })
      .catch((err) => {
        if (err) {
          if (err.response.data.message) setMessage(err.response.data.message);
        }
        // setMessage('Kesalahan server');
      });
  };

  const handleChange = (name, e) => {
    setInput({ ...input, [name]: e.target.value });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="nik" label="NIK" value={input.nik || ''} onChange={(e) => handleChange('nik', e)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={input.password || ''}
          onChange={(e) => handleChange('password', e)}
        />
      </Stack>

      <LoadingButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Daftar
      </LoadingButton>
      {message && message}
    </>
  );
}
