import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Button,
  Grid,
  IconButton,
  TableFooter,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import SaveIcon from '@mui/icons-material/Save';
import RowComponent from '../../../components/RowComponent';
import Iconify from '../../../components/iconify';
import { createBarangRo, getRequestOrderList } from '../../../utils/api';

function FormRequest({ closeModal }) {
  const dispatch = useDispatch();
  const drow = useSelector((state) => state.requestOrder);
  const requestorder = useSelector((state) => state.requestOrder);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const handleAddRow = () => {
    const noID = moment().valueOf();
    dispatch({ type: 'NEW_REQUEST_ORDER', data: { id: noID } });
  };
  const [input, setInput] = useState('');

  const handleMessage = (str) => {
    setMessage(str);
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!input) {
      handleMessage('Urgency belum di isi');
      setLoading(false);
      return;
    }
    const json = {
      urgency: input,
      barang: requestorder,
    };
    createBarangRo(json)
      .then((result) => {
        console.log(result);
        console.log(result.status);
        if (result.status === 200) {
          dispatch({ type: 'REMOVE_REQUEST_ORDER' });
          setInput('');
          handleMessage('Berhasil Menambah Request Order');
          getData();
          closeModal();
        } else {
          handleMessage('Gagal Menambah Request Order');
        }
        setLoading(false);
      })
      .catch((err) => {
        handleMessage('Kesalahan server, silahkan ulangi lagi');
        console.log(err);
        setLoading(false);
      });
  };

  const getData = () => {
    getRequestOrderList()
      .then((result) => {
        const s = result.data.data;
        console.log(s);
        dispatch({ type: 'NEW_REQUEST_ORDER_LIST', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
        <Table stickyHeader sx={{ width: 1200, marginBottom: 2 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Kode Material</TableCell>
              <TableCell width="30%">Nama Material</TableCell>
              <TableCell width="10%" align="left">
                Spesifikasi
              </TableCell>
              <TableCell width="10%" align="left">
                Jumlah
              </TableCell>
              <TableCell width="5%" align="left">
                Satuan
              </TableCell>
              <TableCell width="15%" align="left">
                Keterangan
              </TableCell>
              <TableCell width="5%" align="right">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drow.map((item, index) => {
              return <RowComponent {...item} key={index} />;
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6} align="right">
                Tambah Material
              </TableCell>
              <TableCell align="right">
                <IconButton color="primary" onClick={handleAddRow}>
                  <Iconify icon="eva:plus-fill" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Grid sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, justifyContent: 'space-between' }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" sx={{ mt: -1 }}>
            Urgency
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input}
            label="Urgency"
            size="small"
            sx={{ mr: 2 }}
            onChange={(e) => setInput(e.target.value)}
          >
            <MenuItem value="LOW">LOW</MenuItem>
            <MenuItem value="NORMAL">NORMAL</MenuItem>
            <MenuItem value="URGENT">URGENT</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleSubmit} disabled={loading}>
          {!loading && (
            <>
              <SaveIcon sx={{ mr: 1 }} /> Simpan
            </>
          )}
          {loading && <CircularProgress color="inherit" size={24} />}
        </Button>
      </Grid>
      {message}
    </div>
  );
}

export default FormRequest;
