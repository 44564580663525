import { createStore } from 'redux';

const initialState = {
  requestOrder: [],
  requestOrderList: [],
  barangMasuk: [],
  barangMasukOrder: [],
  barangMasukRequestOrder: [],
  barangRequestOrder: [],
  barangKeluar: [],
  barangKeluarList: [],
  material: [],
  warehouse: [],
  inventory: [],
  barangKeluarMaterial: [],
  assetProcerumentDeployment: [],
  assetCategory: [],
  assetEmployeeDivision: [],
  assetEmployeePosition: [],
  assetOfficeEquipment: [],
  locationSite: [],
  asset: [],
  condition: [],
  nik: '',
  nama: '',
};

const rootReducer = (state = initialState, action) => {
  let data = [];
  let filterData = [];
  let updatedData = [];
  switch (action.type) {
    case 'NEW_NIK':
      return { ...state, nik: action.data };
    case 'NEW_NAMA':
      return { ...state, nama: action.data };

    case 'NEW_WAREHOUSE':
      return { ...state, warehouse: action.data };
    case 'ADD_WAREHOUSE':
      data = [...state.warehouse];
      data.push(action.data);
      return { ...state, warehouse: data };
    case 'UPDATE_WAREHOUSE':
      data = [...state.warehouse];
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = item.id === action.data.id ? action.data : item;
        return newData;
      });
      // simpan data ke state
      return { ...state, warehouse: updatedData };
    case 'DELETE_WAREHOUSE':
      data = [...state.warehouse];
      // update data yg sudah di update
      filterData = data.filter((e) => e.id !== action.data.id);
      // simpan data ke state
      return { ...state, warehouse: filterData };
    case 'NEW_INVENTORY':
      return { ...state, inventory: action.data };
    case 'ADD_INVENTORY':
      data = [...state.inventory];
      data.push(action.data);
      return { ...state, inventory: data };
    case 'UPDATE_INVENTORY':
      data = [...state.inventory];
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = item.id === action.data.id ? action.data : item;
        return newData;
      });
      // simpan data ke state
      return { ...state, inventory: updatedData };
    case 'DELETE_INVENTORY':
      data = [...state.inventory];
      // update data yg sudah di update
      filterData = data.filter((e) => e.id !== action.data.id);
      // simpan data ke state
      return { ...state, inventory: filterData };
    case 'NEW_MATERIAL':
      return { ...state, material: action.data };
    case 'ADD_MATERIAL':
      data = [...state.material];
      data.push(action.data);
      return { ...state, material: data };
    case 'UPDATE_MATERIAL':
      data = [...state.material];
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = item.partCode === action.data.partCode ? action.data : item;
        return newData;
      });
      // simpan data ke state
      return { ...state, material: updatedData };
    case 'DELETE_MATERIAL':
      data = [...state.material];
      // update data yg sudah di update
      filterData = data.filter((e) => e.codePart !== action.data.codePart);
      // simpan data ke state
      return { ...state, material: filterData };
    case 'NEW_REQUEST_ORDER':
      data = [...state.requestOrder];
      data.push(action.data);
      return { ...state, requestOrder: data };
    case 'UPDATE_REQUEST_ORDER':
      data = [...state.requestOrder];
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = item.id === action.data.id ? action.data : item;
        return newData;
      });
      // simpan data ke state
      return { ...state, requestOrder: updatedData };
    case 'REMOVE_REQUEST_ORDER_BY_ID':
      data = [...state.requestOrder];
      filterData = data.filter((item) => item.id !== action.id);
      return { ...state, requestOrder: filterData };
    case 'REMOVE_REQUEST_ORDER':
      return { ...state, requestOrder: [] };
    case 'NEW_BARANGMASUK':
      return { ...state, barangMasuk: action.data };
    case 'NEW_BARANGMASUK_ORDER':
      data = [...state.barangMasukOrder];
      data.push(action.data);
      return { ...state, barangMasukOrder: data };
    case 'UPDATE_BARANGMASUK_ORDER':
      data = [...state.barangMasukOrder];
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = item.id === action.data.id ? action.data : item;
        return newData;
      });
      // simpan data ke state
      return { ...state, barangMasukOrder: updatedData };
    case 'REMOVE_BARANGMASUK_ORDER_BY_ID':
      data = [...state.barangMasukOrder];
      filterData = data.filter((item) => item.id !== action.id);
      return { ...state, barangMasukOrder: filterData };
    case 'REMOVE_BARANGMASUK_ORDER':
      return { ...state, barangMasukOrder: [] };
    case 'NEW_BARANGMASUK_REQUESTORDER':
      // data = [...state.barangMasukOrder];
      // data.push(action.data);
      return { ...state, barangMasukRequestOrder: action.data };
    case 'UPDATE_BARANGMASUK_REQUESTORDER':
      data = [...state.barangMasukRequestOrder];
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = item.id === action.data.id ? action.data : item;
        return newData;
      });
      // simpan data ke state
      return { ...state, barangMasukRequestOrder: updatedData };
    case 'REMOVE_BARANGMASUK_REQUESTORDER_BY_ID':
      data = [...state.barangMasukRequestOrder];
      filterData = data.filter((item) => item.id !== action.id);
      return { ...state, barangMasukRequestOrder: filterData };
    case 'REMOVE_BARANGMASUK_REQUESTORDER':
      return { ...state, barangMasukRequestOrder: [] };
    case 'NEW_REQUEST_ORDER_LIST':
      data = [...action.data];
      data.sort((a, b) => {
        const propertiA = a.noRo;
        const propertiB = b.noRo;
        if (propertiA < propertiB) {
          return 1; // Mengubah urutan 1 dengan -1 untuk descending
        }
        if (propertiA > propertiB) {
          return -1; // Mengubah urutan -1 dengan 1 untuk descending
        }
        return 0;
      });
      return { ...state, requestOrderList: data };
    case 'NEW_BARANG_KELUAR':
      return { ...state, barangKeluar: action.data };
    case 'ADD_BARANG_KELUAR':
      data = [...state.barangKeluar];
      data.push(action.data);
      return { ...state, barangKeluar: data };
    case 'UPDATE_BARANG_KELUAR':
      data = [...state.barangKeluar];
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = item.id === action.data.id ? action.data : item;
        return newData;
      });
      // simpan data ke state
      return { ...state, barangKeluar: updatedData };
    case 'REMOVE_BARANG_KELUAR_BY_ID':
      data = [...state.barangKeluar];
      filterData = data.filter((item) => item.id !== action.id);
      return { ...state, barangKeluar: filterData };
    case 'REMOVE_BARANG_KELUAR':
      return { ...state, barangKeluar: [] };
    case 'NEW_BARANG_KELUAR_LIST':
      return { ...state, barangKeluarList: action.data };
    case 'ADD_BARANG_KELUAR_LIST':
      data = [...state.barangKeluarList];
      data.push(action.data);
      return { ...state, barangKeluarList: data };
    case 'UPDATE_BARANG_KELUAR_LIST':
      data = [...state.barangKeluarList];
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = item.id === action.id ? action.data : item;
        return newData;
      });
      // simpan data ke state
      return { ...state, barangKeluarList: updatedData };
    case 'REMOVE_BARANG_KELUAR_LIST_BY_ID':
      data = [...state.barangKeluarList];
      filterData = data.filter((item) => item.id !== action.id);
      return { ...state, barangKeluarList: filterData };
    case 'REMOVE_BARANG_KELUAR_LIST':
      return { ...state, barangKeluarList: [] };
    case 'NEW_BARANG_KELUAR_MATERIAL':
      return { ...state, barangKeluarMaterial: action.data };
    case 'ADD_BARANG_KELUAR_MATERIAL':
      data = [...state.barangKeluarMaterial];
      data.push(action.data);
      return { ...state, barangKeluarMaterial: data };
    case 'REMOVE_BARANG_KELUAR_MATERIAL_BY_ID':
      data = [...state.barangKeluarMaterial];
      filterData = data.filter((item) => item.id !== action.id);
      return { ...state, barangKeluarMaterial: filterData };
    case 'NEW_BARANG_KELUAR_PROCERUMENT_DEPLOYMENT':
      return { ...state, assetProcerumentDeployment: action.data };
    case 'NEW_BARANG_KELUAR_CATEGORY':
      return { ...state, assetCategory: action.data };
    case 'NEW_BARANG_KELUAR_EMPLOYEE_DIVISION':
      return { ...state, assetEmployeeDivision: action.data };
    case 'NEW_BARANG_KELUAR_EMPLOYEE_POSITION':
      return { ...state, assetEmployeePosition: action.data };
    case 'NEW_BARANG_KELUAR_OFFICE_EQUIPMENT':
      return { ...state, assetOfficeEquipment: action.data };
    case 'NEW_BARANG_KELUAR_LOCATION_SITE':
      return { ...state, locationSite: action.data };
    case 'NEW_BARANG_CONDITION':
      return { ...state, condition: action.data };
    case 'NEW_ASSET':
      return { ...state, asset: action.data };
    case 'ADD_ASSET':
      data = [...state.asset];
      data.push(action.data);
      return { ...state, asset: data };
    case 'UPDATE_ASSET':
      data = [...state.asset];
      updatedData = data.map((item) => {
        const newData = item.id === action.data.id ? action.data : item;
        return newData;
      });
      return { ...state, asset: updatedData };
    case 'DELETE_ASSET':
      data = [...state.asset];
      filterData = data.filter((e) => e.codePart !== action.data.codePart);
      return { ...state, asset: filterData };
    default:
      return state;
  }
};

export const store = createStore(rootReducer);

store.subscribe(() => {
  console.log('store change', store.getState());
});
