import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Button,
  Grid,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
// import moment from 'moment';
import moment from 'moment-timezone';
import SaveIcon from '@mui/icons-material/Save';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import BarangMasukRowComponent from '../../../components/BarangMasukRowComponent';
import { createBarangMasukRo, getRequestOrderProsesList } from '../../../utils/api';

function FormRequestOrderList(props) {
  const data = props.data;
  const dispatch = useDispatch();
  const drow = useSelector((state) => state.barangMasukRequestOrder);
  const barangMasukOrder = useSelector((state) => state.barangMasukRequestOrder);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [input, setInput] = useState(data);

  useEffect(() => {
    console.log(input);
  }, [input]);

  const handleMessage = (str) => {
    setMessage(str);
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  const handleChange = (name, e) => {
    setInput({ ...input, [name]: e.target.value });
  };

  const handleSubmit = () => {
    setLoading(true);
    const json = {
      tanggal: moment().format('YYYY-MM-DD'),
      requestorder: input,
      barang: barangMasukOrder,
    };
    createBarangMasukRo(json)
      .then((result) => {
        console.log(result);
        console.log(result.status);
        if (result.status === 200) {
          // dispatch({ type: 'REMOVE_BARANGMASUK_REQUESTORDER' });
          handleMessage('Berhasil Menambah Request Order');
          // getData();
        } else {
          handleMessage('Gagal Menambah Request Order');
        }
        setLoading(false);
      })
      .catch((err) => {
        handleMessage('Kesalahan server, silahkan ulangi lagi');
        console.log(err);
        setLoading(false);
      });
  };

  const getData = () => {
    getRequestOrderProsesList(data.noRo)
      .then((result) => {
        const s = result.data.data;
        const updatedS = s.map((e) => ({ ...e, tipe: 'ro', idDetailRequestOrder: e.id }));
        dispatch({ type: 'NEW_BARANGMASUK_REQUESTORDER', data: updatedS });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
        <Table stickyHeader sx={{ width: 1200, marginBottom: 2 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Kode Material</TableCell>
              <TableCell width="30%">Nama Material</TableCell>
              <TableCell width="10%" align="left">
                Spesifikasi
              </TableCell>
              <TableCell width="5%" align="left">
                Satuan
              </TableCell>
              <TableCell width="15%" align="left">
                Keterangan
              </TableCell>
              <TableCell width="10%" align="left">
                Jumlah
              </TableCell>
              <TableCell width="10%" align="left">
                Jumlah Diterima
              </TableCell>
              <TableCell width="5%" align="right">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drow.map((item, index) => {
              return <BarangMasukRowComponent {...item} key={index} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          id="outlined-basic"
          label="Nomor Purchase Order"
          variant="outlined"
          size="small"
          sx={{ mr: 2, mb: 2.5 }}
          value={input.noPo}
          onChange={(e) => handleChange('noPo', e)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} size="small" locale="id" fullWidth>
          <DatePicker
            label="Tanggal No PO"
            sx={{ mr: 2 }}
            inputFormat=""
            slotProps={{
              textField: {
                size: 'small',
                helperText: `Tanggal Tersimpan ${moment(input.createdDatePo).format('YYYY - MM - DD')}`,
              },
            }}
            onChange={(newValue) => {
              setInput({ ...input, createdDatePo: moment(newValue).format('YYYY-MM-DD') });
            }}
          />
        </LocalizationProvider>
        <TextField
          id="outlined-basic"
          label="Nomor Purchase Requisition"
          variant="outlined"
          size="small"
          sx={{ mr: 2, mb: 2.5 }}
          value={input.noPr}
          onChange={(e) => handleChange('noPr', e)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} size="small" locale="id" fullWidth>
          <DatePicker
            label="Tanggal No PR"
            sx={{ mr: 2 }}
            slotProps={{
              textField: {
                size: 'small',
                helperText: `Tanggal Tersimpan ${moment(input.createdDatePr).format('YYYY - MM - DD')}`,
              },
            }}
            onChange={(newValue) => {
              setInput({ ...input, createdDatePr: moment(newValue).format('YYYY-MM-DD') });
            }}
          />
        </LocalizationProvider>
        <div>
          <Button variant="contained" onClick={handleSubmit} disabled={loading}>
            {!loading && (
              <>
                <SaveIcon sx={{ mr: 1 }} /> Simpan
              </>
            )}
            {loading && <CircularProgress color="inherit" size={24} />}
          </Button>
        </div>
      </Grid>
      {message}
    </div>
  );
}

export default FormRequestOrderList;
