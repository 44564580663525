import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { getInventory } from '../../utils/api';
// sections
import { ListToolbar, ListHead } from '../../sections/@dashboard/listHead';

const TABLE_HEAD = [
  { id: 'material', label: 'Material', alignRight: false },
  { id: 'warehouse', label: 'Warehouse', alignRight: false },
  { id: 'stockMasuk', label: 'Stock Masuk', alignRight: false },
  { id: 'stockKeluar', label: 'Stock Keluar', alignRight: false },
  { id: 'stockAwal', label: 'Stock Awal', alignRight: false },
  { id: 'stockAkhir', label: 'Stock Akhir', alignRight: false },
  { id: 'periode', label: 'Periode', alignRight: false },
  { id: 'tipe', label: 'Tipe', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.material.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Inventory() {
  const INVENTORYLIST = useSelector((state) => state.inventory);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('material');

  const [filterMaterial, setFilterMaterial] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = INVENTORYLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByMaterial = (event) => {
    setPage(0);
    setFilterMaterial(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - INVENTORYLIST.length) : 0;

  const filteredInventory = applySortFilter(INVENTORYLIST, getComparator(order, orderBy), filterMaterial);

  const isNotFound = !filteredInventory.length && !!filterMaterial;

  const getData = () => {
    getInventory()
      .then((result) => {
        const s = result.data.data;
        dispatch({ type: 'NEW_INVENTORY', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Container maxWidth="100%">
        <Helmet>
          <title> Inventory </title>
        </Helmet>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Inventory
          </Typography>
          <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label={'"month" and "year"'} views={['month', 'year']} size="small" />
              </DemoContainer>
            </LocalizationProvider>
            <Button
              variant="contained"
              color="success"
              startIcon={<Iconify icon="eva:plus-fill" />}
              size="large"
              sx={{ marginTop: 1, marginLeft: 2 }}
            >
              Export
            </Button>
          </Grid>
        </Stack>
        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterMaterial={filterMaterial}
            onFilterMaterial={handleFilterByMaterial}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={INVENTORYLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredInventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, deskripsi, warehouse, stockMasuk, stockKeluar, stockAwal, stockAkhir, periode, tipe } =
                      row;
                    const selectedInventory = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedInventory}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedInventory} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {deskripsi}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {warehouse}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{stockMasuk}</TableCell>

                        <TableCell align="left">{stockKeluar}</TableCell>

                        <TableCell align="left">{stockAwal}</TableCell>

                        <TableCell align="left">{stockAkhir}</TableCell>
                        <TableCell align="left">{periode}</TableCell>
                        <TableCell align="left">
                          <Label color={(tipe === 'Asset' && 'error') || 'success'}>{sentenceCase(tipe)}</Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ada hasil untuk &nbsp;
                            <strong>&quot;{filterMaterial}&quot;</strong>.
                            <br /> Silahkan coba lagi dengan menggunakan kata yang berbeda.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={INVENTORYLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
