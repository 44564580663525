// component
import Inventory2Icon from '@mui/icons-material/Inventory2';
import StorageIcon from '@mui/icons-material/Storage';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import OutputIcon from '@mui/icons-material/Output';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';

import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Menu',
    path: '#',
    icon: '',
  },
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Request Order',
    path: '/dashboard/order',
    icon: <LocalMallIcon />,
  },
  {
    title: 'Barang Masuk',
    path: '/dashboard/barang-masuk',
    icon: <LoginIcon />,
  },
  {
    title: 'Barang Keluar',
    path: '/dashboard/barang-keluar',
    icon: <LogoutIcon />,
    children: [
      {
        title: 'Inventory',
        path: '/dashboard/barang-keluar/inventory',
      },
      {
        title: 'Asset',
        path: '/dashboard/barang-keluar/asset',
      },
    ],
  },
  {
    title: 'Asset',
    path: '/dashboard/asset',
    icon: <StorageIcon />,
  },
  {
    title: 'Data',
    path: '/dashboard/data',
    icon: <Inventory2Icon />,
  },
  {
    title: 'Data Lainnya',
    path: '#',
    icon: '',
  },
  {
    title: 'Material',
    path: '/dashboard/material',
    icon: <Inventory2Icon />,
  },
  {
    title: 'Warehouse',
    path: '/dashboard/warehouse',
    icon: <WarehouseIcon />,
  },
  // {
  //   title: 'Data Asset Building',
  //   path: '/dashboard/data-asset-building',
  //   icon: <DescriptionIcon />,
  // },
  // {
  //   title: 'Data Asset Category',
  //   path: '/dashboard/data-asset-category',
  //   icon: <DescriptionIcon />,
  // },
  // {
  //   title: 'Data Asset Condition',
  //   path: '/dashboard/data-asset-condition',
  //   icon: <DescriptionIcon />,
  // },
  // {
  //   title: 'Data Asset Employee Position',
  //   path: '/dashboard/data-asset-employee-position',
  //   icon: <DescriptionIcon />,
  // },
  // {
  //   title: 'Data Asset Fixtures',
  //   path: '/dashboard/data-asset-fixtures',
  //   icon: <DescriptionIcon />,
  // },
  // {
  //   title: 'Data Asset Office Equipment',
  //   path: '/dashboard/data-asset-office-equipment',
  //   icon: <DescriptionIcon />,
  // },
  // {
  //   title: 'Data Asset Procerument Deployment',
  //   path: '/dashboard/data-asset-procerument-deployment',
  //   icon: <DescriptionIcon />,
  // },
  // {
  //   title: 'Inventory',
  //   path: '/dashboard/inventory',
  //   icon: <StorageIcon />,
  // },
  // {
  //   title: 'Financial',
  //   path: '/dashboard/financial',
  //   icon: <AccountBalanceWalletIcon />,
  // },
  // {
  //   title: 'Stock Keluar',
  //   path: '/dashboard/stockkeluar',
  //   icon: <Inventory2Icon />,
  // },
  // {
  //   title: 'Perpanjangan',
  //   path: '/dashboard/renewal',
  //   icon: <OutputIcon />,
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
