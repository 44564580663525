import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import SaveIcon from '@mui/icons-material/Save';
import RowComponent from '../../../components/RowComponent';

function FormTerima({ closeModal }) {
  const dispatch = useDispatch();
  const drow = useSelector((state) => state.requestOrder);
  const handleAddRow = () => {
    console.log('adwdaw');
    const noID = moment().valueOf();
    dispatch({ type: 'NEW_REQUEST_ORDER', data: { id: noID } });
  };
  return (
    <div style={{ width: '100%' }}>
      <Grid sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <TextField
          id="outlined-basic"
          label="No RO/PO"
          variant="outlined"
          size="small"
          type="number"
          value="2423423"
          sx={{ mr: 2 }}
          disabled="true"
        />
      </Grid>
      <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
        <Table stickyHeader sx={{ width: 1200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Kode Material</TableCell>
              <TableCell width="30%">Nama Material</TableCell>
              <TableCell width="10%" align="left">
                Spesifikasi
              </TableCell>
              <TableCell width="10%" align="left">
                Jumlah
              </TableCell>
              <TableCell width="5%" align="left">
                Satuan
              </TableCell>
              <TableCell width="10%" align="left">
                Harga per/barang
              </TableCell>
              <TableCell width="15%" align="left">
                Keterangan
              </TableCell>
              <TableCell width="5%" align="right">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drow.map((item, index) => {
              return <RowComponent {...item} key={index} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper
        sx={{
          textAlign: 'center',
          marginTop: 1,
        }}
      >
        <Button variant="contained">
          <SaveIcon sx={{ mr: 1 }} /> Simpan
        </Button>
      </Paper>
    </div>
  );
}

export default FormTerima;
