import { Helmet } from 'react-helmet-async';
import { sample } from 'lodash';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppWidgetSummaryLeftIcon,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import {
  getBarangMinimumStock,
  getBarangOutstandingStock,
  getTotalBarangKeluar,
  getTotalBarangMasuk,
  getTotalMinimumStock,
  getTotalOutstandingStock,
} from '../utils/api';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [totalBarangKeluar, setTotalBarangKeluar] = useState(0);
  const [totalBarangMasuk, setTotalBarangMasuk] = useState(0);
  const [totalBarangMinimumStock, setTotalBarangMinimumStock] = useState(0);
  const [totalBarangOutstandingStock, setTotalBarangOutstandingStock] = useState(0);
  const [barangMinimumStock, setBarangMinimumStock] = useState([]);
  const [barangOutstandingStock, setBarangOutstandingStock] = useState([]);

  const gettotalBarangKeluar = () => {
    getTotalBarangKeluar()
      .then((result) => {
        const d = result.data.data;
        console.log(d);
        setTotalBarangKeluar(d.stockKeluar);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const gettotalBarangMasuk = () => {
    getTotalBarangMasuk()
      .then((result) => {
        const d = result.data.data;
        console.log(d);
        setTotalBarangMasuk(d.stockMasuk);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const gettotalMinimumStock = () => {
    getTotalMinimumStock()
      .then((result) => {
        const d = result.data.data;
        console.log(d);
        setTotalBarangMinimumStock(d.totalMinimumStock);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const gettotalOutstandingStock = () => {
    getTotalOutstandingStock()
      .then((result) => {
        const d = result.data.data;
        console.log(d);
        setTotalBarangOutstandingStock(d.totalOutstandingStock);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getbarangMinimumStock = () => {
    getBarangMinimumStock()
      .then((result) => {
        const d = result.data.data;
        console.log(d);
        const mapping = d.map((e) => ({
          partCode: e.partCode,
          deskripsi: e.deskripsi,
          kanan: `Minimum Stock ${e.minimumStock}`,
          kiri: `Stock saat ini ${e.stockAkhir}`,
        }));
        setBarangMinimumStock(mapping);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getbarangOutstandingStock = () => {
    getBarangOutstandingStock()
      .then((result) => {
        const d = result.data.data;
        console.log(d);
        const mapping = d.map((e) => ({
          partCode: e.partCode,
          deskripsi: e.deskripsi,
          kanan: `Total Stock ${e.qty}`,
          kiri: `Stock belum datang ${e.qty - e.qtyreceipt}`,
        }));
        setBarangOutstandingStock(mapping);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    gettotalBarangKeluar();
    gettotalBarangMasuk();
    gettotalMinimumStock();
    gettotalOutstandingStock();
    getbarangMinimumStock();
    getbarangOutstandingStock();
  }, []);
  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="100%">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummaryLeftIcon
              title="Barang Keluar"
              total={totalBarangKeluar}
              icon={'ant-design:android-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummaryLeftIcon
              title="Barang Masuk"
              total={totalBarangMasuk}
              color="info"
              icon={'ant-design:apple-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummaryLeftIcon
              title="Barang Minimal Stock"
              total={totalBarangMinimumStock}
              color="warning"
              icon={'ant-design:windows-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummaryLeftIcon
              title="Barang Outstanding"
              total={totalBarangOutstandingStock}
              color="error"
              icon={'ant-design:bug-filled'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppNewsUpdate title="Barang sudah Minimal Stock" list={barangMinimumStock} />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppNewsUpdate title="Barang Outstanding" list={barangOutstandingStock} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
