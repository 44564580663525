import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, MenuItem, Menu } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;
  const btnStyle =
    path !== '#'
      ? {
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }
      : {
          '&.active': {
            color: 'text.primary',
            fontWeight: 'fontWeightBold',
          },
        };
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const handleClickMenu = (event) => {
    if (children) {
      handleOpenMenu(event);
      event.preventDefault();
    }
  };

  return (
    <>
      <StyledNavItem
        component={children ? 'div' : RouterLink}
        to={path}
        sx={btnStyle}
        disabled={path === '#' || isOpen}
        onClick={handleClickMenu}
      >
        {path !== '#' && <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>}
        <ListItemText disableTypography primary={title} />
        {info && info}
      </StyledNavItem>
      {children && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          elevation={0}
          sx={{ marginLeft: 23, marginTop: -5 }}
        >
          {children.map((child) => (
            <MenuItem key={child.title} component={RouterLink} to={child.path} onClick={handleCloseMenu}>
              {child.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
