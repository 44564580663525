import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';

function BarangMasukRowComponent(data) {
  const requestOrderMasuk = useSelector((state) => state.barangMasukRequestOrder);
  const [input, setInput] = useState(requestOrderMasuk);

  useEffect(() => {
    const find = requestOrderMasuk.find((e) => e.id === data.id);
    setInput(find);
  }, [data, requestOrderMasuk]);

  const handleChange = (name, e) => {
    const newInput = { ...input, [name]: e.target.value };
    setInput(newInput);
    dispatch({ type: 'UPDATE_BARANGMASUK_REQUESTORDER', data: newInput });
  };

  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch({ type: 'REMOVE_BARANGMASUK_REQUESTORDER_BY_ID', id: data.id });
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: 0.5 }}>{input.partCode}</TableCell>
      <TableCell sx={{ padding: 0.5 }}>{input.deskripsi}</TableCell>
      <TableCell sx={{ padding: 0.5 }}>{input.spesifikasi}</TableCell>
      <TableCell sx={{ padding: 0.5 }}>{input.sat}</TableCell>
      <TableCell sx={{ padding: 0.5 }}>{input.note}</TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        {input.qtyreceipt}/{input.qty}
      </TableCell>
      <TableCell sx={{ padding: 0.5 }}>
        <TextField
          id="outlined-basic"
          label="Jumlah Diterima"
          variant="outlined"
          size="small"
          value={input.qtynew || 0}
          onChange={(e) => handleChange('qtynew', e)}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default BarangMasukRowComponent;
