import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  Button,
  Grid,
  IconButton,
  TableFooter,
  CircularProgress,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import SaveIcon from '@mui/icons-material/Save';
import BarangMasukLocalRowComponent from '../../../components/BarangMasukLocalRowComponent';
import Iconify from '../../../components/iconify';
import { createBarangMasuk, getBarangMasuk } from '../../../utils/api';

function FormBarangMasuk({ handleClose }) {
  const dispatch = useDispatch();
  const drow = useSelector((state) => state.barangMasukOrder);
  const barangMasukOrder = useSelector((state) => state.barangMasukOrder);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const handleAddRow = () => {
    const noID = moment().valueOf();
    dispatch({
      type: 'NEW_BARANGMASUK_ORDER',
      data: { id: noID, idDetailRequestOrder: '', partCode: '', qty: 0, tipe: 'lokal' },
    });
  };

  const handleMessage = (str) => {
    setMessage(str);
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  const handleSubmit = () => {
    setLoading(true);
    const json = {
      tanggal: moment().format('YYYY-MM-DD'),
      barang: barangMasukOrder,
    };
    createBarangMasuk(json)
      .then((result) => {
        console.log(result);
        console.log(result.status);
        if (result.status === 200) {
          dispatch({ type: 'REMOVE_BARANGMASUK_ORDER' });
          handleMessage('Berhasil Menambah Request Order');
          getData();
          // handleClose();
        } else {
          handleMessage('Gagal Menambah Request Order');
        }
        setLoading(false);
      })
      .catch((err) => {
        handleMessage('Kesalahan server, silahkan ulangi lagi');
        console.log(err);
        setLoading(false);
      });
  };

  const getData = () => {
    getBarangMasuk()
      .then((result) => {
        const s = result.data.data;
        console.log(s);
        dispatch({ type: 'NEW_BARANGMASUK', data: s });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
        <Table stickyHeader sx={{ width: 1200, marginBottom: 2 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Kode Material</TableCell>
              <TableCell width="30%">Nama Material</TableCell>
              <TableCell width="10%" align="left">
                Spesifikasi
              </TableCell>
              <TableCell width="10%" align="left">
                Jumlah
              </TableCell>
              <TableCell width="5%" align="left">
                Satuan
              </TableCell>
              <TableCell width="15%" align="left">
                Keterangan
              </TableCell>
              <TableCell width="5%" align="right">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drow.map((item, index) => {
              return <BarangMasukLocalRowComponent {...item} key={index} />;
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>* Kode material bisa di kosongkan jika material tidak ada pada list</TableCell>
              <TableCell colSpan={4} align="right">
                Tambah Material
              </TableCell>
              <TableCell align="right">
                <IconButton color="primary" onClick={handleAddRow}>
                  <Iconify icon="eva:plus-fill" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Grid sx={{ display: 'flex', float: 'right' }}>
        <Button variant="contained" onClick={handleSubmit} disabled={loading}>
          {!loading && (
            <>
              <SaveIcon sx={{ mr: 1 }} /> Simpan
            </>
          )}
          {loading && <CircularProgress color="inherit" size={24} />}
        </Button>
      </Grid>
      {message}
    </div>
  );
}

export default FormBarangMasuk;
