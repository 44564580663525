import { MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Logout({ handleClose }) {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('nik');
    localStorage.removeItem('nama');
    handleClose();
    navigate('/');
  };
  return (
    <MenuItem onClick={() => handleLogout()} sx={{ m: 1 }}>
      Logout
    </MenuItem>
  );
}

export default Logout;
