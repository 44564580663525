import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import Inventory from './pages/Inventory';
import Material from './pages/Material';
import Warehouse from './pages/Warehouse';
import Financial from './pages/Financial';
import StockKeluar from './pages/StockKeluar';
import BarangKeluarInventory from './pages/BarangKeluar/Inventory';
import BarangKeluarAsset from './pages/BarangKeluar/Asset';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import RequestOrder from './pages/RequestOrder';
import LocalOrder from './pages/LocalOrder';
import Asset from './pages/Asset';
import DataAssetBuilding from './pages/DataAssetBuilding';
import DataAssetCategory from './pages/DataAssetCategory';
import DataAssetCondition from './pages/DataAssetCondition';
import DataAssetEmployeeDivision from './pages/DataAssetEmployeeDivision';
import DataAssetEmployeePosition from './pages/DataAssetEmployeePosition';
import DataAssetFixtures from './pages/DataAssetFixtures';
import DataAssetOfficeEquipment from './pages/DataAssetOfficeEquipment';
import DataAssetProcerumentDeployment from './pages/DataAssetProcerumentDeployment';
import RegisterPage from './pages/RegisterPage';

import Auth from './utils/Auth';
import Guest from './utils/Guest';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: (
            <Auth>
              <DashboardAppPage />
            </Auth>
          ),
        },
        {
          path: 'order',
          element: (
            <Auth>
              <RequestOrder />
            </Auth>
          ),
        },
        {
          path: 'barang-masuk',
          element: (
            <Auth>
              <LocalOrder />
            </Auth>
          ),
        },
        {
          path: 'barang-keluar/inventory',
          element: (
            <Auth>
              <BarangKeluarInventory />
            </Auth>
          ),
        },
        {
          path: 'barang-keluar/asset',
          element: (
            <Auth>
              {' '}
              <BarangKeluarAsset />
            </Auth>
          ),
        },
        { path: 'barang-keluar', element: <Navigate to="/dashboard/app" /> },
        {
          path: 'asset',
          element: (
            <Auth>
              <Asset />
            </Auth>
          ),
        },
        {
          path: 'data',
          element: (
            <Auth>
              <Inventory />{' '}
            </Auth>
          ),
        },
        {
          path: 'inventory',
          element: (
            <Auth>
              <Inventory />
            </Auth>
          ),
        },
        {
          path: 'material',
          element: (
            <Auth>
              <Material />
            </Auth>
          ),
        },
        {
          path: 'warehouse',
          element: (
            <Auth>
              <Warehouse />
            </Auth>
          ),
        },
        {
          path: 'financial',
          element: (
            <Auth>
              <Financial />
            </Auth>
          ),
        },
        {
          path: 'stockkeluar',
          element: (
            <Auth>
              <StockKeluar />
            </Auth>
          ),
        },
        {
          path: 'products',
          element: (
            <Auth>
              <ProductsPage />
            </Auth>
          ),
        },
        {
          path: 'blog',
          element: (
            <Auth>
              <BlogPage />
            </Auth>
          ),
        },
        {
          path: 'data-asset-building',
          element: (
            <Auth>
              <DataAssetBuilding />
            </Auth>
          ),
        },
        {
          path: 'data-asset-category',
          element: (
            <Auth>
              <DataAssetCategory />
            </Auth>
          ),
        },
        {
          path: 'data-asset-condition',
          element: (
            <Auth>
              <DataAssetCondition />
            </Auth>
          ),
        },
        {
          path: 'data-asset-exployee-division',
          element: (
            <Auth>
              <DataAssetEmployeeDivision />
            </Auth>
          ),
        },
        {
          path: 'data-asset-exployee-position',
          element: (
            <Auth>
              <DataAssetEmployeePosition />
            </Auth>
          ),
        },
        {
          path: 'data-asset-fixtures',
          element: (
            <Auth>
              <DataAssetFixtures />
            </Auth>
          ),
        },
        {
          path: 'data-asset-office-equipment',
          element: (
            <Auth>
              <DataAssetOfficeEquipment />
            </Auth>
          ),
        },
        {
          path: 'data-asset-procerument-deployment',
          element: (
            <Auth>
              <DataAssetProcerumentDeployment />
            </Auth>
          ),
        },
      ],
    },
    {
      path: 'masuk',
      element: (
        <Guest>
          <LoginPage />
        </Guest>
      ),
    },
    {
      path: 'daftar',
      element: (
        <Guest>
          <RegisterPage />
        </Guest>
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/masuk" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
